export const ROUTES = {
  HOME: "/",
  PRIVACY_POLICY: "/privacy",
  TERMS: "/tnc",
  BLOG: "/blog",
  APP: "/app",
  DISCLAIMER: "/disclaimer",
  COOKIES_POLICY: "/cookies-policy",
  DELETE_ACCOUNT: "/delete",
  CHANGE_LANGUAGE: "/change-language",
  ABOUT: "/about",
  CONTACT: "/contact",
  SUBSCRIBE: "/subscribe",
  FAQ: "/getwebfaq",
  SIX_DEGREE_SEPERATION_WIKI_URL:
    "https://en.wikipedia.org/wiki/Six_degrees_of_separation",
  GET_LOCATION: "/location?input=",
  SERVICES: "/services",
  SERVICESMAP: "servicemap",
  CHECK_USER_REGISTERED: "/web/signup/checkUserRegistred",
  sendOTP: "/web/signup/init",
  SIGNUP_FORM: "/web/signUp/complete",
  CONTACT_US_FORM: "/jobPortal/contactUs",
  RECAPTCHA_SITE_KEY: "6LeekCEqAAAAAPJ3C1LZXhwiw6DEg2YR9IyT-Jdi", //production
  // RECAPTCHA_SITE_KEY: "6Lf0dBsqAAAAADIEXbUZRD3gP5ugLZ_fl5sJ4bgc", //testing
  INSTAGRAM_LINK: "https://www.instagram.com/bataiyo.official/",
  FACEBOOK_LINK:
    "https://www.facebook.com/profile.php?id=61560529440864&mibextid=ZbWKwL",
  YOUTUBE_LINK: "https://www.youtube.com/channel/UCh2YhhO5bdamxyC1WSBfcXQ",
  TWITTER_LINK: "https://x.com/bataiyoofficial?t=s9FWulgAI-X1FujT5xYscQ&s=09",
  LINKEDIN_LINK: "https://www.linkedin.com/company/bataiyo/",
  CLOUDFRONT_URL: "https://d2uvzz1jka6usw.cloudfront.net/",
  APP_STORE_URL: "https://apps.apple.com/in/app/bataiyo/id6504960016",
  PLAY_STORE_URL:
    "https://play.google.com/store/apps/details?id=com.bataiyo.mobile&pli=1",
  WHATS_APP_URL: `https://wa.me/${"918530196306"}?text=${encodeURIComponent(
    "Hi, I am interested in your application and would appreciate it if you could share more information."
  )}`,
  JOBS_WHATS_APP_URL: `https://wa.me/${"918530196306"}?text=${encodeURIComponent(
    "Hi, I am interested in your Jobs Application and would appreciate it if you could share more information."
  )}`,
  REGISTRATION_TOKEN:
    "mcbYAuVtzipYqXXsas12E8ZrWR0VIszqzHsltt17mxr57Pbawqry3c3B55qRhZ79",
};
