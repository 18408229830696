import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { HireCategoriesImg } from "../../constant/ImagesS3";
import { jobsBataiyoAdvantages } from "../../constant/staticData";
import "./JobLandingPage.css";

function HireCategoriesImgSection() {
  const isMediumScreen = useMediaQuery("(max-width: 980px)");
  const [currentCard, setCurrentCard] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isAutoFlipping, setIsAutoFlipping] = useState(true);
  const [hoveredCard, setHoveredCard] = useState(null);
  const sectionRef = useRef(null);
  let viewTimer = useRef(null);

  // Start auto-flipping when section enters the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          viewTimer.current = setTimeout(() => setIsAutoFlipping(true), 3000);
        } else {
          clearTimeout(viewTimer.current);
          setIsAutoFlipping(false);
        }
      },
      { threshold: 0.5 }
    );
    if (sectionRef.current) observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let interval;

    // Delay the start of flipping to show the first card initially
    const startAutoFlip = () => {
      setIsFlipped(true);
      interval = setInterval(() => {
        setCurrentCard((prev) => (prev + 1) % jobsBataiyoAdvantages?.length);
      }, 3000);
    };

    if (!isMediumScreen && isAutoFlipping && hoveredCard === null) {
      const delayTimer = setTimeout(startAutoFlip, 3000);
      return () => {
        clearTimeout(delayTimer);
        clearInterval(interval);
      };
    }
  }, [isMediumScreen, isAutoFlipping, hoveredCard]);

  // Handle card hover and auto-flip starting after hover-out
  const handleCardHover = (index) => {
    if (!isMediumScreen) {
      setHoveredCard(index);
      setIsAutoFlipping(false);
    }
  };

  const handleCardHoverLeave = (index) => {
    if (!isMediumScreen) {
      setHoveredCard(null);
      setCurrentCard((index + 1) % jobsBataiyoAdvantages?.length);
      setIsFlipped(true);
      setIsAutoFlipping(true);
    }
  };
  return (
    <section className="hire-category-container">
      <div className="dashboard-text-box">How Bataiyo Is Different?</div>
      <div className="hire-category-img-container">
        <div className="hire-category-img-box">
          <img
            src={HireCategoriesImg}
            alt="categories"
            className="hire-category-img"
            loading="lazy"
          />
        </div>
        {!isMediumScreen && (
          <div className="job-advantage-img-box" ref={sectionRef}>
            <div className="job-advantage-container">
              {jobsBataiyoAdvantages?.map((item, index) => (
                <div
                  className={`card-container ${
                    hoveredCard === index ||
                    (isFlipped && currentCard === index && hoveredCard === null)
                      ? "card-flip"
                      : ""
                  }`}
                  key={item?.title}
                  onMouseEnter={() => handleCardHover(index)}
                  onMouseLeave={() => handleCardHoverLeave(index)}
                >
                  <div className="card-inner">
                    <div className="card-front">
                      <img
                        src={item?.img}
                        alt={item?.alt}
                        className="advantage-card-image"
                        loading="lazy"
                      />
                      <div className="card-title">{item?.title}</div>
                    </div>
                    <div className="card-back">
                      <div className="card-title">{item?.title}</div>
                      <div className="card-description">
                        {item?.description}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default HireCategoriesImgSection;
