import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import React from "react";
import { styles } from "./styleSheet";

function AllServicesSkeleton() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  return (
    <>
      <div style={styles.allServicesTextBox(isSmallScreen, isMediumScreen)}>
        <div style={styles.textFieldBox(isMediumScreen)}>
          <Skeleton
            variant="text"
            width={isSmallScreen ? "100%" : "70%"}
            height={isSmallScreen ? 50 : 80}
            sx={styles.allServicesText(isSmallScreen, isMediumScreen)}
          />
          <Skeleton
            variant="rectangular"
            width={isSmallScreen ? "100%" : "60%"}
            height={isSmallScreen ? 50 : 60}
            sx={{ borderRadius: "16px" }}
          />
        </div>

        <div style={styles.categoriesMainContainer(isMediumScreen)}>
          <Skeleton
            variant="text"
            width={isSmallScreen ? "100%" : "40%"}
            height={isSmallScreen ? 40 : 60}
            sx={styles.categoriesTextStyle(isSmallScreen)}
          />
          <div style={styles.categoriesSkeletonBox}>
            {Array.from(new Array(14)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangle"
                width={isSmallScreen ? "30%" : "15%"}
                height={isSmallScreen ? 20 : 30}
                sx={{ borderRadius: "20px" }}
              />
            ))}
          </div>
        </div>
      </div>
      <Skeleton
        variant="rectangular"
        width={100}
        height={4}
        style={{ width: "100%", marginTop: "12px", marginBottom: "24px" }}
      />
      <Grid container spacing={2}>
        {Array.from(new Array(30)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={75}
              style={{ borderRadius: "16px" }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default AllServicesSkeleton;
