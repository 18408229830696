import React from "react";
import { DashboardImg } from "../../constant/ImagesS3";
import "./JobLandingPage.css";
function DashboardScreenshot() {
  return (
    <section className="dashboard-container">
      <div className="dashboard-text-box">
        Simplified Dashboard for Businesses to hire!
      </div>
      <div className="dashboard-img-box">
        <img
          className="dashboard-img"
          src={DashboardImg}
          alt="dashboard"
          loading="lazy"
        />
      </div>
    </section>
  );
}

export default DashboardScreenshot;
