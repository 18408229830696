import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

const signupFormValidationSchema = Yup.object({
  userType: Yup.string().required("User Type is required"),
  businessType: Yup.string().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Business Type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  mobileNumber: Yup.string()
    .matches(/^(?:\+91|91|0)?[123456789]\d{9}$/, "Phone number is not valid")
    .required("Please enter a phone number."),
  serviceId: Yup.string().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please select a service."),
    otherwise: (schema) => schema.notRequired(),
  }),
  name: Yup.string()
    .matches(/^[A-Za-z]+(?:[A-Za-z\s]+)*$/, "Name is not valid")
    .min(2)
    .max(32)
    .required("Please enter full name."),
  gender: Yup.string().required("Please select your gender."),
  // DOB: Yup.date()
  //   .required("Date of birth is required")
  //   .max(new Date(), "Date of birth cannot be in the future")
  //   .nullable(),
  businessName: Yup.string().when("businessType", {
    is: "Business",
    then: (schema) =>
      schema
        .matches(/^[A-Za-z]+(?:[A-Za-z\s]+)*$/, "Business Name is not valid")
        .min(2)
        .max(64)
        .required("Please enter business name."),
  }),
  experience: Yup.number().when("userType", {
    is: "Service Provider",
    then: (schema) =>
      schema
        .required("Please enter an experience value.")
        .when("businessType", {
          is: "Business",
          then: (schema) =>
            schema
              .min(0, "Experience must be at least 0 year.")
              .max(1000, "Experience must be at most 1000 years."),
          otherwise: (schema) =>
            schema
              .min(0, "Experience must be at least 0 year.")
              .max(100, "Experience must be at most 100 years."),
        }),
    otherwise: (schema) => schema.notRequired(),
  }),

  isEighteenOrOlder: Yup.boolean().when("userType", {
    is: "Service Provider",
    then: (schema) =>
      schema.oneOf([true], "You must confirm you are 18 years or older."),
    otherwise: (schema) => schema.oneOf([false]),
  }),
  isAutoApproved: Yup.boolean(),
  otp: Yup.array()
    .of(Yup.string().matches(/^\d$/, "Must be a 4 digit"))
    .length(4, "OTP must be 4 digit")
    .required("Please enter a 4-digit OTP."),
  serviceOrCurrentLocationObject: Yup.object().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please enter service location."),
    otherwise: (schema) => schema.required("Please enter current location."),
  }),
  homeLocationObject: Yup.object().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please enter home location."),
    otherwise: (schema) => schema.required("Please enter home location."),
  }),
});

const isDisableForSubmit = (formik) => {
  return (
    isEmpty(formik?.values?.homeLocationObject) ||
    isEmpty(formik?.values?.serviceOrCurrentLocationObject) ||
    (formik?.values?.userType === "Service Provider" &&
      Object.keys(formik.errors)?.some((key) =>
        ["isAutoApproved", "isEighteenOrOlder"]?.includes(key)
      ))
  );
};

const jobContactUsFormValidationSchema = Yup.object({
  contactUsLookingToHire: Yup.string()
    .matches(
      /^[A-Za-z]+(?:[A-Za-z\s]+)*$/,
      "This field can only contain letters and spaces."
    )
    .min(2, "This field must be at least 2 characters long.")
    .max(64, "This field cannot exceed 64 characters.")
    .required("Please specify what you are looking to hire."),

  contactUsPrimaryEmailID: Yup.string()
    .email("Please enter a valid email address.")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    )
    .required("Email address is required."),

  contactUsCompanyName: Yup.string()
    .matches(
      /^[A-Za-z]+(?:[A-Za-z\s]+)*$/,
      "Company name can only contain letters and spaces."
    )
    .min(2, "Company name must be at least 2 characters long.")
    .max(64, "Company name cannot exceed 64 characters.")
    .required("Company name is required."),
});

export {
  isDisableForSubmit,
  jobContactUsFormValidationSchema,
  signupFormValidationSchema,
};
