import React from "react";
import {
  PeerToPeerNetworkImg,
  SuperAppImg,
  ZeroCommissionImg,
} from "../../constant/ImagesS3";
import "./WhyBataiyo.css";

function WhyBataiyo() {
  return (
    <div className="why-bataiyo-container" id="why-us">
      <h1 className="why-bataiyo-heading">Why Bataiyo?</h1>
      <div className="why-bataiyo-img-box">
        <div className="why-bataiyo-img-inner-box">
          <img
            src={ZeroCommissionImg}
            alt="Zero Commission"
            className="why-bataiyo-img"
          />
        </div>
        <div className="why-bataiyo-img-inner-box">
          <img
            src={PeerToPeerNetworkImg}
            alt="Peer to Peer Network"
            className="why-bataiyo-img"
          />
        </div>
        <div className="why-bataiyo-img-inner-box">
          <img src={SuperAppImg} alt="Super App" className="why-bataiyo-img" />
        </div>
      </div>
    </div>
  );
}

export default WhyBataiyo;
