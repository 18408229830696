import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constant/routes";
import { blogsListData } from "../../constant/staticData";
import { getInitialChar, truncateString } from "../../constant/utils";
import LatestBlogSkeleton from "./LatestBlogSkeleton";
import { styles } from "./styleSheet";

function BlogListCard() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1000px)");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.blogMainContainer(isSmallScreen, isMediumScreen)}>
      <div style={styles.latestBlogContainer(isMediumScreen)}>
        <div style={styles.latestBlogHeadingBox(isSmallScreen)}>
          {isLoading ? (
            <Skeleton variant="text" height={80} width="40%" />
          ) : (
            <h3 style={styles.latestBlogText(isSmallScreen, isMediumScreen)}>
              All Blogs
            </h3>
          )}
        </div>
        <Grid container spacing={3} justifyContent="start">
          {isLoading
            ? Array.from({ length: 6 }).map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={styles.latestBlogGridBox}
                >
                  <LatestBlogSkeleton />
                </Grid>
              ))
            : blogsListData?.map((blog) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={blog?.id}
                  sx={styles.latestBlogGridBox}
                >
                  <Link
                    to={`${ROUTES.BLOG}/${blog.id}`}
                    style={styles.latestBlogLink}
                  >
                    <Card sx={styles.latestBlogCard}>
                      <CardMedia
                        component="img"
                        alt={blog?.title}
                        height="240px"
                        width="360px"
                        sx={styles.latestBlogImage}
                        image={blog?.coverImage}
                      />
                      <CardContent sx={styles.cardContentBox}>
                        <Box style={styles.cardContainerInnerBox}>
                          <Box sx={styles.cardContentTags}>{blog?.tags}</Box>
                          <Box className="blog-title">
                            {truncateString(blog?.title, 80)}
                          </Box>
                        </Box>
                        <Box
                          sx={styles.topBlogCardAuthorDateBox(isSmallScreen)}
                        >
                          <Box sx={styles.topBlogAuthorImgBox(isSmallScreen)}>
                            {blog?.authorImage ? (
                              <img
                                src={blog?.authorImage}
                                alt="author-icon"
                                style={styles.authorImgStyle}
                                loading="lazy"
                              />
                            ) : (
                              getInitialChar(blog?.authorName)
                            )}
                            <Box sx={styles.latestBlogAuthorName}>
                              {blog?.authorName}
                            </Box>
                          </Box>
                          <Box sx={styles.latestBlogDateStyle}>
                            {blog?.publishDate}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
        </Grid>
      </div>
    </div>
  );
}

export default BlogListCard;
