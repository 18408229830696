import React from "react";
import { featureCrossImg, JobAppImg } from "../../constant/ImagesS3";
import { jobsAppData } from "../../constant/staticData";
import "../../components/AppFeatures/AppFeature.css";
function JobAppSection() {
  return (
    <section className="jobs-main-container">
      <div className="jobs-app-heading">Super App for Your Employees/Team!</div>
      <div className="jobs-app-container">
        <div className="features-img-box">
          <img
            src={JobAppImg}
            alt="bataiyo-app-features"
            className="features-img"
            loading="lazy"
          />
        </div>
        <div className="features-text-box">
          <div className="cross-icon-box">
            <img
              src={featureCrossImg}
              alt="cross-img"
              className="cross-icon"
              loading="lazy"
            />
          </div>
          <div className="features-heading-box">
            <div className="job-app-content-head">Bataiyo App</div>
          </div>
          <div className="features-description">
            {jobsAppData?.description?.map((item, index) => (
              <p className={index === 0 ? "features-para" : ""} key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default JobAppSection;
