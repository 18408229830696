import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { jobsBataiyoAdvantages } from "../../constant/staticData";
import ScrollDots from "../AllServices/ScrollDots";
import "./TheBataiyoAdvantage.css";

function JobHowBataiyoIsDifferent() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1270px)");
  const [currentCard, setCurrentCard] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isAutoFlipping, setIsAutoFlipping] = useState(true);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [flippedCards, setFlippedCards] = useState({});
  const sectionRef = useRef(null);
  let viewTimer = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const cardWidth = container.children[0].getBoundingClientRect()?.width;
        const containerWidth = container.getBoundingClientRect()?.width;
        const scrollLeft = container.scrollLeft;
        const scrollableWidth = container.scrollWidth - containerWidth;

        const cardIndex = Math.floor(scrollLeft / cardWidth);
        const totalDots = 3;
        let dotIndex = cardIndex % totalDots;
        if (scrollLeft >= scrollableWidth) {
          dotIndex = totalDots - 1;
        }
        setActiveIndex(dotIndex);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  // Start auto-flipping when section enters the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          viewTimer.current = setTimeout(() => setIsAutoFlipping(true), 3000);
        } else {
          clearTimeout(viewTimer.current);
          setIsAutoFlipping(false);
        }
      },
      { threshold: 0.5 }
    );
    if (sectionRef.current) observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let interval;

    // Delay the start of flipping to show the first card initially
    const startAutoFlip = () => {
      setIsFlipped(true);
      interval = setInterval(() => {
        setCurrentCard((prev) => (prev + 1) % jobsBataiyoAdvantages?.length);
      }, 3000);
    };

    if (!isMediumScreen && isAutoFlipping && hoveredCard === null) {
      const delayTimer = setTimeout(startAutoFlip, 3000);
      return () => {
        clearTimeout(delayTimer);
        clearInterval(interval);
      };
    }
  }, [isMediumScreen, isAutoFlipping, hoveredCard]);

  // Handle card hover and auto-flip starting after hover-out
  const handleCardHover = (index) => {
    if (!isMediumScreen) {
      setHoveredCard(index);
      setIsAutoFlipping(false);
    }
  };

  const handleCardHoverLeave = (index) => {
    if (!isMediumScreen) {
      setHoveredCard(null);
      setCurrentCard((index + 1) % jobsBataiyoAdvantages?.length);
      setIsFlipped(true);
      setIsAutoFlipping(true);
    }
  };
  const handleCardClick = (index) => {
    if (isMediumScreen) {
      setFlippedCards((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };
  return (
    <section ref={sectionRef} className="adv-main">
      <div className="adv-text-content">
        {/* <img
          src={AdvantageHeadingicon}
          alt="heading-icon"
          className="heading-icon"
          loading="lazy"
        /> */}
        <h1 className="adv-heading">How Bataiyo is Different?</h1>
      </div>

      <div className="advantage-container">
        {jobsBataiyoAdvantages?.map((item, index) => (
          <div
            className={`card-container ${
              hoveredCard === index ||
              (isFlipped && currentCard === index && hoveredCard === null)
                ? "card-flip"
                : ""
            }`}
            key={item?.title}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={() => handleCardHoverLeave(index)}
          >
            <div className="card-inner">
              <div className="card-front">
                <img
                  src={item?.img}
                  alt={item?.alt}
                  className="advantage-card-image"
                  loading="lazy"
                />
                <div className="card-title">{item?.title}</div>
              </div>
              <div className="card-back">
                <div className="card-title">{item?.title}</div>
                <div className="card-description">{item?.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mobile-container">
        <div className="mobile-inner-container" ref={containerRef}>
          {jobsBataiyoAdvantages?.map((item, index) => (
            <div
              className={`card-container ${
                flippedCards[index] ? "mob-card-flip" : ""
              }`}
              key={item?.title}
              onClick={() => handleCardClick(index)}
            >
              <div className="mob-card-inner">
                <div className="card-front">
                  <img
                    src={item?.img}
                    alt={item?.alt}
                    className="advantage-card-image"
                    loading="lazy"
                  />
                  <div className="card-title">{item?.title}</div>
                </div>
                <div className="card-back">
                  <div className="card-title">{item?.title}</div>
                  <div className="card-description">{item?.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ScrollDots isSmallScreen={isSmallScreen} activeIndex={activeIndex} />
      </div>
    </section>
  );
}

export default JobHowBataiyoIsDifferent;
