import { CircularProgress, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import SuccessDialog from "../../components/common/SuccessDialog";
import TextInput from "../../components/common/TextInput";
import { jobContactUsFormValidationSchema } from "../../constant/formValidationSchema";
import { ErrorImg } from "../../constant/ImagesS3";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../constant/messages";
import { postContactUsFormData } from "../../services/form.service";
import AlertDialog from "../SignUpFormVerification/AlertDialog";
import { styles } from "./styleSheet";
function ContactUsForm() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const formik = useFormik({
    initialValues: {
      contactUsLookingToHire: "",
      contactUsPrimaryEmailID: "",
      contactUsCompanyName: "",
    },
    validationSchema: jobContactUsFormValidationSchema,
    onSubmit: async (values) => {
      const payload = {
        companyName: values.contactUsCompanyName,
        email: values.contactUsPrimaryEmailID,
        lookingFor: values.contactUsLookingToHire,
      };
      try {
        const res = await postContactUsFormData(payload);
        if (res) {
          setIsDialogOpen(true);
          formik.resetForm();
        }
      } catch (error) {
        setIsAlertDialog(true);
        setErrorMessage(
          error?.response?.data?.message || ERROR_MESSAGES.formSubmitError
        );
        console.error(ERROR_MESSAGES?.postDataError, error);
      }
    },
  });
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={styles.contactUsFormContainer}
      >
        <TextInput
          name="contactUsPrimaryEmailID"
          label="Email ID *"
          placeholder="Enter Your Email Address"
          type="email"
          value={formik.values.contactUsPrimaryEmailID}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.contactUsPrimaryEmailID &&
            Boolean(formik.errors.contactUsPrimaryEmailID)
          }
          helperText={
            formik.touched.contactUsPrimaryEmailID &&
            formik.errors.contactUsPrimaryEmailID
          }
        />
        <TextInput
          name="contactUsCompanyName"
          label="Company Name *"
          placeholder="Enter Your Company Name"
          type="text"
          value={formik.values.contactUsCompanyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.contactUsCompanyName &&
            Boolean(formik.errors.contactUsCompanyName)
          }
          helperText={
            formik.touched.contactUsCompanyName &&
            formik.errors.contactUsCompanyName
          }
        />
        <TextInput
          name="contactUsLookingToHire"
          label="What are you looking for? *"
          placeholder="Tell us about the talent you’re seeking"
          type="text"
          value={formik.values.contactUsLookingToHire}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.contactUsLookingToHire &&
            Boolean(formik.errors.contactUsLookingToHire)
          }
          helperText={
            formik.touched.contactUsLookingToHire &&
            formik.errors.contactUsLookingToHire
          }
        />
        <button
          type="submit"
          style={styles.contactUsSubmitBtn(isSmallScreen)}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <>
              <CircularProgress size={24} style={styles.circularProgressbar} />
              Submitting...
            </>
          ) : (
            "Submit"
          )}
        </button>
      </form>
      <SuccessDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={SUCCESS_MESSAGES.contactUsFormSubmitted}
      />
      <AlertDialog
        isDialogOpen={isAlertDialog}
        handleClose={() => setIsAlertDialog(false)}
        message={errorMessage}
        image={ErrorImg}
      />
    </>
  );
}
export default ContactUsForm;
