import axios from "axios";
import { ERROR_MESSAGES } from "../constant/messages";
// const Api = process.env.REACT_APP_API_URL;
// const Api = "https://dev-api.bataiyo.com/v1";
const Api = "https://api.bataiyo.com/v1";
// const Api = "https://tkzggf.bataiyo.com/v1";
const axiosInstance = axios.create({
  baseURL: Api,
});

/* The `axiosInstance.interceptors.request.use()` is setting up a request interceptor for the Axios instance `axiosInstance`. */
axiosInstance.interceptors.request.use(
  (config) => {
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* Here is setting up a response interceptor for the Axios instance `axiosInstance`. */
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* The `fetchData` function fetches data from a specified URL using axios and handles errors with optional chaining and status code messages and returning the `result` property from the data object in the response received from the specified URL.*/
export const fetchData = async (url) => {
  try {
    const res = await axiosInstance.get(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};

/* The `postData` function sends a POST request to a specified URL with an initial request body and handles errors appropriately and returning the `result` property from the `data` object in the response. */
export const postData = async (url, initialRequestBody) => {
  try {
    const res = await axiosInstance.post(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.postDataError, error);
    throw error;
  }
};

export default axiosInstance;
