import React, { memo, useState } from "react";
import { useDialog } from "../../context/DialogContext";
import CustomDialog from "../SignUpFormVerification/CustomDialog";
import "./HeroSection.css";
import { useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  HeroSectionAppFrameImg,
  HeroSectionAppFrameMobImg,
  PhoneImg,
} from "../../constant/ImagesS3";
const HeroSection = () => {
  const { handleDialogOpen } = useDialog();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [handleSuccessDialogOpen, setHandleSuccessDialogOpen] = useState(false);
  return (
    <>
      <section className="hero-section">
        <div className="hero-section-inner-box">
          <div className="hero-text-box">
            <img src={PhoneImg} alt="call" className="phone-img" />
            <h1 className="hero-heading">
              Tired of calling your friends for{" "}
              <span className="hero-blue-text">recommendations</span>?
            </h1>
            <div className="hero-subheading">
              <span className="hero-blue-text">Bataiyo</span> makes it easy to
              find services through your friends & their recommendations.
            </div>
            <div className="hero-download-btn-box">
              <button
                className="hero-download-btn"
                onClick={() => setHandleSuccessDialogOpen(true)}
              >
                Download Now
                <ArrowForwardIosIcon className="right-arrow-icon" />
              </button>
            </div>{" "}
          </div>
          <div className="hero-img-box">
            <img
              src={
                isSmallScreen
                  ? HeroSectionAppFrameMobImg
                  : HeroSectionAppFrameImg
              }
              alt="group-img"
              className="hero-group-img"
            />
          </div>
        </div>
      </section>
      {handleDialogOpen && (
        <CustomDialog
          isDialogOpen={handleSuccessDialogOpen}
          handleClose={() => setHandleSuccessDialogOpen(false)}
        />
      )}
    </>
  );
};

export default memo(HeroSection);
