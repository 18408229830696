const dialogContainer = (isSmallScreen) => ({
  "& .MuiDialog-paper": {
    margin: "0",
    borderRadius: "16px",
    width: isSmallScreen ? "90%" : "431px",
  },
  "& .MuiDialog-container": {
    alignItems: "center",
  },
});
const backdropStyles = {
  backdropFilter: "blur(3px)",
  backgroundColor: "rgba(0,0,0,0.5)",
};
const dialogBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "24px",
  padding: "24px",
  borderRadius: "16px",
});
const headingBox = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "16px",
  alignItems: "center",
  width: "100%",
};
const signupHeadingText = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  fontSize: "20px",
  fontWeight: 500,
  fontFamily: "Outfit",
  lineHeight: "25px",
  color: "#000000",
};
const formContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "18px",
  width: "100%",
  height: "100%",
};
const crossIcon = {
  fontSize: "18px",
  "&:hover": {
    cursor: "pointer",
  },
};
const stepperContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
};
const stepsText = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "20px",
  color: "#000000",
};
const stepperLineBox = { display: "flex", gap: "6px" };
const firstStepperLine = {
  height: "6px",
  width: "50px",
  backgroundColor: "#FF820F",
  borderRadius: "100px",
};
const secondStepperLine = (activeStep) => ({
  height: "6px",
  width: "50px",
  backgroundColor: activeStep === 1 || activeStep === 2 ? "#0072DD" : "#CCCCCC",
  borderRadius: "100px",
});
const thirdStepperLine = (activeStep) => ({
  height: "6px",
  width: "50px",
  backgroundColor: activeStep === 2 ? "#06A445" : "#CCCCCC",
  borderRadius: "100px",
});
const contactUsFormContainer = {
  backgroundColor: "#FFFFFF",
  borderRadius: "23px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "start",
  textAlign: "start",
  gap: "32px",
  padding: "32px",
  width: "100%",
  height: "100%",
  boxShadow: "0px 0px 35px 0px #0000000D",
  boxSizing: "border-box",
};
const contactUsSubmitBtn = (isSmallScreen) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "end",
  width: isSmallScreen ? "100%" : "45%",
  backgroundColor: "#0072DD",
  color: "#FFFFFF",
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: "600",
  padding: isSmallScreen ? "8px" : "10px",
  border: "1px solid #0072DD",
  borderRadius: "8px",
  cursor: "pointer",
  fontFamily: "Sora,sans-serif",
  marginTop: "16px",
});
const circularProgressbar = { color: "white", marginRight: 8 };

export const styles = {
  dialogContainer,
  backdropStyles,
  dialogBox,
  headingBox,
  signupHeadingText,
  formContainer,
  crossIcon,
  stepperContainer,
  firstStepperLine,
  secondStepperLine,
  stepperLineBox,
  stepsText,
  thirdStepperLine,
  contactUsFormContainer,
  contactUsSubmitBtn,
  circularProgressbar,
};
