import CloseIcon from "@mui/icons-material/Close";
import { Dialog, useMediaQuery } from "@mui/material";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDialog } from "../../context/DialogContext";
import useSignupForm from "../../hooks/useSignupForm";
import FormDetailsStep from "../formSteps/FormDetailsStep";
import SelectUserType from "../formSteps/SelectUserType";
import { styles } from "./styleSheet";

const SignUpForm = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { isDialogOpen, handleDialogClose } = useDialog();
  const { formik, errorMessage, handleErrorFalse, error } = useSignupForm();
  const formikRef = useRef(formik);
  const [activeStep, setActiveStep] = useState(0);

  /* This `useEffect` hook is responsible for toggling the visibility of a reCAPTCHA badge based on the value of the `isDialogOpen`. */
  useEffect(() => {
    const badge = document.querySelector(".grecaptcha-badge");
    if (badge) {
      badge.style.visibility = isDialogOpen ? "visible" : "hidden";
    }
  }, [isDialogOpen]);

  /* This `useEffect` hook is triggered whenever the `isDialogOpen` state changes. If `isDialogOpen` is true, it sets the `activeStep` state to 0 and resets the form using the `resetForm`.*/
  useEffect(() => {
    if (isDialogOpen) {
      setActiveStep(0);
      formikRef.current.resetForm();
    }
  }, [isDialogOpen]);

  /* This `useEffect` hook is updating the `formikRef.current` value whenever the `formik` dependency changes. It ensures that the `formikRef.current` always holds the latest value of the `formik` object.*/
  useEffect(() => {
    formikRef.current = formik;
  }, [formik]);

  /* This `useEffect` hook is triggered whenever the `formik.values` dependency changes. It checks if the `formikRef.current` exists (is not null or undefined), and if it does, it calls the `validateForm()` method on the `formikRef.current` object.*/
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [formik.values]);

  /* This `useEffect` hook is responsible for resetting specific form fields and touched values when the `userType` or `businessType` values change in the `formik` object. */
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("serviceOrCurrentLocationObject", {});
      formikRef.current.setFieldValue("homeLocationObject", {});
      formikRef.current.setTouched({
        userType: false,
        businessType: false,
        mobileNumber: false,
        serviceId: false,
        gender: false,
        // DOB: false,
        name: false,
        businessName: false,
        experience: false,
        serviceOrCurrentLocationObject: false,
        homeLocationObject: false,
        isEighteenOrOlder: false,
        isAutoApproved: false,
        otp: false,
      });
    }
  }, [formik.values.userType, formik.values.businessType]);

  const handleNext = useCallback(() => {
    formikRef.current.setTouched({
      userType: false,
      businessType: false,
      mobileNumber: false,
      gender: false,
      // DOB: false,
      serviceId: false,
      name: false,
      businessName: false,
      experience: false,
      serviceOrCurrentLocationObject: false,
      homeLocationObject: false,
      isEighteenOrOlder: false,
      isAutoApproved: false,
      otp: false,
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    formikRef.current.setFieldValue("serviceOrCurrentLocationObject", {});
    formikRef.current.setFieldValue("homeLocationObject", {});
  }, []);

  return (
    <>
      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            handleDialogClose();
          }}
          sx={styles.dialogContainer(isSmallScreen)}
          slotProps={{
            backdrop: {
              style: styles.backdropStyles,
            },
          }}
        >
          <div style={styles.dialogBox(isSmallScreen)}>
            <div style={styles.headingBox}>
              <div style={styles.signupHeadingText}>
                <div>Sign Up</div>
                <div>
                  <CloseIcon
                    onClick={handleDialogClose}
                    sx={styles.crossIcon}
                  />
                </div>
              </div>
            </div>
            <div style={styles.stepperContainer}>
              <div style={styles.stepsText}>STEP {activeStep + 1}/3</div>
              <div style={styles.stepperLineBox}>
                <div style={styles.firstStepperLine}></div>
                <div style={styles.secondStepperLine(activeStep)}></div>
                <div style={styles.thirdStepperLine(activeStep)}></div>
              </div>
            </div>
            <div style={styles.formContainer}>
              {activeStep === 0 && (
                <SelectUserType formik={formik} onNextStep={handleNext} />
              )}
              {activeStep === 1 && (
                <FormDetailsStep
                  formik={formik}
                  onNextStep={handleNext}
                  onPrevStep={handleBack}
                  activeStep={activeStep}
                  errorMessage={errorMessage}
                  handleErrorFalse={handleErrorFalse}
                  error={error}
                />
              )}
              {activeStep === 2 && (
                <FormDetailsStep
                  formik={formik}
                  onNextStep={handleNext}
                  onPrevStep={handleBack}
                  activeStep={activeStep}
                  errorMessage={errorMessage}
                  handleErrorFalse={handleErrorFalse}
                  error={error}
                />
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default memo(SignUpForm);
