import { useMediaQuery } from "@mui/material";
import React from "react";
import { JobHeroImg, JobMobHeroImg } from "../../constant/ImagesS3";
import "./JobLandingPage.css";

function JobHeroSection() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const handleButtonItemClick = (path) => {
    if (path.startsWith("/jobs/#")) {
      const sectionId = path.substring(2);
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.location.href = path;
    }
  };
  return (
    <>
      <section className="job-hero-container">
        <div className="job-hero-text-box">
          <div className="job-hero-heading">
            Looking to <span className="hero-blue-text">Hire</span> Freelancers
            or Full-Time <span className="hero-blue-text">Professionals</span>?
          </div>
          <div className="job-hero-subheading">
            Quickly search through your{" "}
            <span className="hero-blue-text">Trusted</span> Network.
          </div>
        </div>
        <div className="job-img-box">
          <img
            src={isSmallScreen ? JobMobHeroImg : JobHeroImg}
            alt="hero-img"
            className="job-hero-img"
          />
        </div>
        <div>
          <button
            className="get-early-access-btn"
            onClick={() => handleButtonItemClick(`#contact-us`)}
          >
            Get Early Access Now
          </button>
        </div>
      </section>
    </>
  );
}

export default JobHeroSection;
