import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Autocomplete,
  FormControl,
  FormLabel,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { FormStyles, styles } from "./styleSheet";

const ReusableAutocomplete = ({
  isServices,
  options,
  value,
  onChange,
  onBlur,
  formLabel,
  placeholder,
  error,
  helperText,
  inputProps,
  isOptionEqualToValue,
  getOptionLabel,
  filterOptions,
  onKeyDown,
}) => {
  const [hasValue, setHasValue] = useState(Boolean(value));
  const [open, setOpen] = useState(false);
  const shouldShowError = error && !hasValue;
  const inputRef = useRef(null);
  const handleIconClick = () => {
    setOpen(!open);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <FormControl fullWidth margin="normal" sx={styles.inputContainer}>
      <FormLabel style={styles.formLabel}>{formLabel}</FormLabel>
      <Autocomplete
        options={options}
        value={value !== undefined ? value : null}
        onChange={(event, newValue) => {
          setHasValue(Boolean(newValue));
          onChange(event, newValue);
        }}
        onBlur={(event) => {
          onBlur(event);
        }}
        onKeyDown={onKeyDown}
        isOptionEqualToValue={isOptionEqualToValue}
        blurOnSelect
        filterSelectedOptions
        freeSolo
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        sx={styles.autocompleteBoxStyle}
        PaperComponent={({ children }) => (
          <CustomPaperComponent>{children}</CustomPaperComponent>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            error={shouldShowError}
            helperText={shouldShowError ? helperText : ""}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              style: FormStyles.inputField,
              endAdornment: isServices ? (
                <InputAdornment
                  position="end"
                  style={styles.dropDownStyle}
                  onClick={handleIconClick}
                >
                  {open ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  )}
                </InputAdornment>
              ) : null,
              inputRef: inputRef,
            }}
          />
        )}
      />
    </FormControl>
  );
};

// Custom Paper component definition
const CustomPaperComponent = ({ children, ...otherProps }) => {
  return (
    <Paper {...otherProps} style={FormStyles.autocompletePaper}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }
        return React.cloneElement(child, {
          style: {
            ...child.props.style,
            ...FormStyles.autocompletePaper["& > *"],
          },
        });
      })}
    </Paper>
  );
};

CustomPaperComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

ReusableAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  formLabel: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  inputProps: PropTypes.object,
  isOptionEqualToValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
  isServices: PropTypes.bool,
};

export default ReusableAutocomplete;
