import { useEffect, useState } from "react";

const useWhatsAppBanner = () => {
  const [whBannerVisible, setWhBannerVisible] = useState(false);

  useEffect(() => {
    // Show the banner after a delay of 30 seconds
    const delayTimeoutId = setTimeout(() => {
      setWhBannerVisible(true);
    }, 30000);

    // Add scroll event listener to show the banner when user scrolls below 100vh
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      if (scrollPosition > viewportHeight) {
        setWhBannerVisible(true);
        // Once shown, remove the scroll listener
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the timeout and scroll listener on component unmount
    return () => {
      clearTimeout(delayTimeoutId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hideBanner = () => {
    setWhBannerVisible(false);
  };

  return {
    whBannerVisible,
    hideBanner,
  };
};

export default useWhatsAppBanner;
