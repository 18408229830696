export const SUCCESS_MESSAGES = {
  contactUsFormSubmitted:
    "Thank you for reaching out! Your message has been received, and we'll get back to you shortly.",
  signupSuccesfully: (
    <>
      Signed Up Successfully!
      <br />
      Have a great experience ahead and grow your network.
    </>
  ),
};
export const ERROR_MESSAGES = {
  fetchDataError: "Error fetching Data:",
  postDataError: "Error for post data:",
  userRegistred: "Requested user phone number is already registered.",
  formSubmitError: "Form submit error:",
  dobErrorMessage: "Date of birth is required.",
};
export const getLocationErrorMessage = (
  isServiceIndividual,
  isOnlyUser,
  isHomeLocation
) => {
  if (isServiceIndividual) {
    return "Please enter service location.";
  } else if (isOnlyUser) {
    return "Please enter current location.";
  } else if (isHomeLocation) {
    return "Please enter home location.";
  }
  return "";
};
