import { CircularProgress } from "@mui/material";
import React, { lazy, Suspense } from "react";
import { sixDegreeSeperationSectionData } from "../../constant/staticData";
import "./SixDegreeSeperationSection.css";

const LottieAnimation = lazy(() =>
  import("../../LottieAnimation/SixDegreeAnimation")
);
function SixDegreeSeperationSection() {
  return (
    <section className="six-degree-section" id="idea">
      <div className="six-degree-section-inner-box">
        <h1 className="idea-heading">Idea</h1>
        <div className="six-degree-section-container">
          <div className="six-degree-img-container">
            <Suspense fallback={<CircularProgress color="primary" />}>
              <LottieAnimation />
            </Suspense>
          </div>
          <div className="six-degree-section-content">
            <h2 className="six-degree-heading">
              {sixDegreeSeperationSectionData?.title}
            </h2>
            {sixDegreeSeperationSectionData?.description?.map((item, index) => (
              <p className="six-degree-para" key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SixDegreeSeperationSection;
