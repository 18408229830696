const userTypeContainer = {
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  gap: "32px",
};
const userTypeHeading = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "25px",
  color: "#000000",
};
const userTypeBox = (isSmallScreen) => ({
  background: "linear-gradient(to left, #FF820F33 0%, #FFF2E5 100%)",
  backgroundColor: "#FFF2E5",
  padding: "15px 8px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: isSmallScreen ? "12px" : "24px",
  position: "relative",
  cursor: "pointer",
  zIndex: "1",
});
const userTypeImg = { width: "56px", height: "56px" };
const userTypeText = (isSmallScreen) => ({
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: isSmallScreen ? "20px" : "24px",
  lineHeight: isSmallScreen ? "24px" : "30px",
  color: "#1a1a1a",
  textOverflow: "word-wrap",
});
const userTypeArrowBox = (isSmallScreen) => ({
  position: "absolute",
  backgroundColor: "#ffc996",
  height: isSmallScreen ? "30px" : "36px",
  width: isSmallScreen ? "30px" : "36px",
  borderRadius: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "12px",
  right: "12px",
  color: "#FF820F",
  zIndex: "-1",
});
const providerTypeBox = (isSmallScreen) => ({
  background: "linear-gradient(to left, #05A44633 0%, #05A44600 100%)",
  backgroundColor: "#E6F6EC",
  padding: "15px 8px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: isSmallScreen ? "6px" : "16px",
  position: "relative",
  cursor: "pointer",
  zIndex: "1",
});
const providerTypeArrowBox = (isSmallScreen) => ({
  position: "absolute",
  backgroundColor: "#8ddbac",
  height: isSmallScreen ? "30px" : "36px",
  width: isSmallScreen ? "30px" : "36px",
  borderRadius: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "12px",
  right: "12px",
  color: "#06A445",
  zIndex: "-1",
});
const errorMessagesStyle = {
  alignSelf: "start",
  fontSize: "0.8rem",
  fontFamily: "Outfit",
  fontWeight: "400",
};
const stepsBottonBox = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  gap: "5px",
  justifyContent: "space-between",
  marginTop: "14px",
  "& .MuiButton-root": {
    fontFamily: "Outfit",
  },
};
const backButton = (isSmallScreen) => ({
  padding: isSmallScreen ? "8px 40px" : "12px 67px",
  fontFamily: "Outfit",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  borderRadius: "100px",
  border: "1px solid #000000",
  textTransform: "none",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});
const stepSubmitButton = (isSmallScreen) => ({
  padding: isSmallScreen ? "10px 0" : "12px 0",
  alignSelf: "end",
  backgroundColor: "#0072DD",
  color: "#FFFFFF",
  borderRadius: "100px",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  fontFamily: "Outfit",
  textTransform: "none",
  width: isSmallScreen ? "133px" : "187px",
  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#0072DD",
  },
  "&.Mui-disabled": {
    opacity: 0.8,
    color: "#FFFFFF",
    backgroundColor: "#0072DD",
  },
});
const signupDescBox = {
  backgroundColor: "#E6F6EC",
  width: "100%",
  borderRadius: "12px",
  padding: "12px",
};
const signupDescText = {
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  color: "#292929",
  margin: "0",
};
const privacyAndTermsText = {
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  color: "#525252",
  textAlign: "center",
};
const userTypeTextBox = {
  display: "flex",
  gap: "4px",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-between",
};
const userTypeDescription = (isSmallScreen) => ({
  fontFamily: "Outfit",
  fontWeight: "400",
  maxWidth: "200px",
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: isSmallScreen ? "18px" : "22px",
  color: "#525252",
});
const blueColorSpan = {
  color: "#0072DD",
};
export const styles = {
  userTypeContainer,
  userTypeHeading,
  userTypeBox,
  providerTypeBox,
  providerTypeArrowBox,
  userTypeImg,
  userTypeText,
  userTypeArrowBox,
  errorMessagesStyle,
  stepsBottonBox,
  stepSubmitButton,
  backButton,
  signupDescBox,
  signupDescText,
  privacyAndTermsText,
  userTypeTextBox,
  userTypeDescription,
  blueColorSpan,
};
