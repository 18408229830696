import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { ExplorerSignupImg, ProviderSignupImg } from "../../constant/ImagesS3";
import { styles } from "./styleSheet";

function SelectUserType({ formik, onNextStep }) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const handleUserTypeChange = (type) => {
    formik.setFieldValue("userType", type);
    onNextStep();
  };
  return (
    <div style={styles.userTypeContainer}>
      <div style={styles.userTypeHeading}>How will you use bataiyo?</div>
      <div
        style={styles.userTypeBox(isSmallScreen)}
        onClick={() => handleUserTypeChange("User")}
      >
        <img src={ExplorerSignupImg} alt="user" style={styles.userTypeImg} />
        <div style={styles.userTypeTextBox}>
          <div style={styles.userTypeText(isSmallScreen)}>Explorer</div>
          <div style={styles.userTypeDescription(isSmallScreen)}>
            Looking for a <span style={styles.blueColorSpan}>Trusted</span>{" "}
            Service Providers
          </div>
        </div>
        <div style={styles.userTypeArrowBox(isSmallScreen)}>
          <ArrowForwardIcon sixe={16} />
        </div>
      </div>
      <div
        style={styles.providerTypeBox(isSmallScreen)}
        onClick={() => handleUserTypeChange("Service Provider")}
      >
        <img
          src={ProviderSignupImg}
          alt="provider"
          style={styles.userTypeImg}
        />
        <div style={styles.userTypeTextBox}>
          <div style={styles.userTypeText(isSmallScreen)}>Service Provider</div>
          <div style={styles.userTypeDescription(isSmallScreen)}>
            Offer your services with{" "}
            <span style={styles.blueColorSpan}>0% commission</span>
          </div>
        </div>
        <div style={styles.providerTypeArrowBox(isSmallScreen)}>
          <ArrowForwardIcon sixe={16} />
        </div>
      </div>{" "}
    </div>
  );
}

SelectUserType.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onNextStep: PropTypes.func,
};

export default memo(SelectUserType);
