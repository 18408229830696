import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { capitalizeWords } from "../../constant/utils";
import ServiceCard from "./ServiceCard";
import { styles } from "./styleSheet";

const CategoryList = ({
  displayedCategories,
  searchFocused,
  filterServices,
  isSmallScreen,
}) => (
  <div>
    {displayedCategories?.map((category, index) => (
      <div
        key={category?.categoryId}
        style={styles.displayCategoriesCont(
          index,
          displayedCategories,
          isSmallScreen
        )}
      >
        {!searchFocused && (
          <div style={styles.categoriesNameText(isSmallScreen)}>
            <div style={styles.categoryImgBox}>
              <img
                src={category?.categoryImage}
                alt={category?.categoryName}
                style={styles.categoryIcon(isSmallScreen)}
              />
              <span>{capitalizeWords(category?.categoryName)}</span>
            </div>
          </div>
        )}
        {category?.subcategories?.map((subcategory) => {
          const hideSubcategoryName = category?.subcategories?.length === 1;
          const filteredServices = filterServices(subcategory?.services);

          return (
            <div
              style={styles.subCategoriesGrid(index, category, isSmallScreen)}
              key={`${subcategory?.subcategoryId}-${subcategory?.subcategoryName}`}
            >
              <Grid container spacing={2}>
                {filteredServices?.length > 0 &&
                  !hideSubcategoryName &&
                  !searchFocused && (
                    <Grid item xs={12}>
                      <div style={styles.subcategoryImgBox(isSmallScreen)}>
                        <img
                          src={subcategory?.subcategoryImage}
                          alt={subcategory?.subcategoryName}
                          style={styles.subcategoryIcon(isSmallScreen)}
                        />
                        <h4 style={styles.subCategoriesText(isSmallScreen)}>
                          {capitalizeWords(subcategory?.subcategoryName)}
                        </h4>
                      </div>
                    </Grid>
                  )}
                {filteredServices?.map((service) => (
                  <ServiceCard
                    key={service?.serviceId}
                    service={service}
                    isSmallScreen={isSmallScreen}
                  />
                ))}
              </Grid>
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

CategoryList.propTypes = {
  displayedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      categoryName: PropTypes.string,
      categoryImage: PropTypes.string,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          subcategoryId: PropTypes.string.isRequired,
          subcategoryName: PropTypes.string,
          subcategoryImage: PropTypes.string,
          services: PropTypes.arrayOf(
            PropTypes.shape({
              serviceId: PropTypes.string.isRequired,
              serviceIcon: PropTypes.string,
              serviceName: PropTypes.string,
            })
          ),
        })
      ),
    })
  ).isRequired,
  searchFocused: PropTypes.bool.isRequired,
  filterServices: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default CategoryList;
