export const servicesStaticData = [
  {
    categoryId: "66976c3445f995d96159916f",
    categoryName: "travel",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/travel2.svg",
    subcategories: [
      {
        subcategoryId: "66976c4b45f995d961599204",
        subcategoryName: "adventure activities",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Air3.svg",
        services: [
          {
            serviceId: "66976c4b45f995d961599207",
            serviceName: "air adventures",
            serviceSortOrder: 141,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/adventureactivities.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/AirS.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "677041d6fdf4be981860ba3b",
            serviceName: "desert adventures",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Desert.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6770417cfdf4be981860ba3a",
            serviceName: "mountain adventures",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mountains.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "67703ff3fdf4be981860ba39",
            serviceName: "water adventures",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Water Adventures.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "677041f6fdf4be981860ba3c",
            serviceName: "wildlife adventures",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Wildlife.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6770420efdf4be981860ba3d",
            serviceName: "winter adventures",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Winter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c3c45f995d96159919f",
        subcategoryName: "hotels",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Stay.svg",
        services: [
          {
            serviceId: "66976c3c45f995d9615991a2",
            serviceName: "backpackers hostel",
            serviceSortOrder: 126,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/backpackerhostels.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Hostel.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3d45f995d9615991a9",
            serviceName: "camping",
            serviceSortOrder: 127,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/camping.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Camping.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3e45f995d9615991b0",
            serviceName: "home stay",
            serviceSortOrder: 128,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/homestay.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HomeStay.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4045f995d9615991be",
            serviceName: "hotel/resort",
            serviceSortOrder: 130,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Hotel.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Resort.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c4145f995d9615991c5",
            serviceName: "lodge",
            serviceSortOrder: 131,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/lodge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Lodge.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "676fc0582aa1c89b5ee4a394",
        subcategoryName: "Rentals",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Rent4.svg",
        services: [
          {
            serviceId: "66976c0945f995d961599068",
            serviceName: "bike rentals",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentals.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BikeRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0c45f995d961599076",
            serviceName: "car rentals",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0d45f995d96159907d",
            serviceName: "cycle rentals",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/CycleRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c4245f995d9615991cb",
        subcategoryName: "shopping",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Shopping3.svg",
        services: [
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "carpet shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Carpet3.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "67705dd00141fa13db3898f3",
            serviceName: "clothes and garments shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Clothes.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c82be0fa4ee39921dff",
            serviceName: "general store",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GeneralStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Grocery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4745f995d9615991ea",
            serviceName: "pottery shop",
            serviceSortOrder: 136,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalcraftsmen.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pottery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6771080169bca30ec6ac4384",
            serviceName: "shoes/footwear shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ShoeShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4345f995d9615991ce",
            serviceName: "souvenirs",
            serviceSortOrder: 132,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Souvenir.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Souvenirs.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c4445f995d9615991d5",
            serviceName: "spices store",
            serviceSortOrder: 133,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/culinarysouvenirs.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SpiceStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toys shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Toys.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4645f995d9615991e3",
            serviceName: "traditional clothing",
            serviceSortOrder: 135,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalclothing.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TClothing.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6771069169bca30ec6ac4383",
            serviceName: "travel and accessory shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TravelShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "6770183a3bd861473dfede33",
        subcategoryName: "tour packages & guide",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Packages5.svg",
        services: [
          {
            serviceId: "66976c4d45f995d96159920e",
            serviceName: "tourist guide",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/touristguide.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ToursitGuide.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c1045f995d961599092",
            serviceName: "tours and travels",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/TourAndTravel.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c1145f995d961599099",
            serviceName: "travel agent",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/TravelAgent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356af8c69575453cb6601",
            serviceName: "visa agent",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/visaassistance.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/VisaAgent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c0845f995d96159905e",
        subcategoryName: "transport",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Transport.svg",
        services: [
          {
            serviceId: "66976c0845f995d961599061",
            serviceName: "auto/e-rickshaw",
            serviceSortOrder: 117,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Auto3.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0a45f995d96159906f",
            serviceName: "bike-taxi",
            serviceSortOrder: 119,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/BikeTaxiDriver.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0f45f995d96159908b",
            serviceName: "cab/taxi",
            serviceSortOrder: 119,
            isActive: true,
            description: "Safe travels, every time.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Cab.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CabTaxi.svg",
            bgColor: "0XFFFFEABC",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0e45f995d961599084",
            serviceName: "driver/chauffeur",
            serviceSortOrder: 122,
            isActive: true,
            description: "Experience the road with confidence.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/DriverImage.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Chauffeur.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "6745bd19cad6997f158b404d",
            serviceName: "parking",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/parkingserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Parking.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "6770f18afdf4be981860ba3e",
        subcategoryName: "Utilities",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Utilites5.svg",
        services: [
          {
            serviceId: "66bd9b4415f9f92376e9fdce",
            serviceName: "bike repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BikeRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9be215f9f92376e9fdcf",
            serviceName: "car repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcd45f995d961598ed6",
            serviceName: "car washer",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/CarWash5.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarWash.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d3245f995d9615997bb",
            serviceName: "delivery",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Delivery3.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Delivery.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66d357588c69575453cb6603",
            serviceName: "forex",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/forex.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Forex.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd345f995d961598f00",
            serviceName: "laundry",
            serviceSortOrder: 170,
            isActive: true,
            description: "Fresh clothes, fresher feelings.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Laundry.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Laundry.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bea45f995d961598f99",
            serviceName: "mobile shop/repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MobileRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6745bd19cad6997f158b404d",
            serviceName: "parking",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/parkingserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Parking.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "petrol/gas center",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetrolPump.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3545f995d9615997ce",
            serviceName: "pollution center",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pollutionpucshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pollution.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf045f995d961598fbc",
            serviceName: "shoe repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ShoeRepair.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ShoeRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop and repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Watch.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "67704efe5c35d7506d012d82",
        subcategoryName: "Zone",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Zone3.svg",
        services: [
          {
            serviceId: "66976c7c45f995d96159933e",
            serviceName: "tattoo artist",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooartist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Tattoo.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976bbe45f995d961598e71",
    categoryName: "daily needs",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/DailyNeeds.svg",
    subcategories: [
      {
        subcategoryId: "66976bbf45f995d961598e74",
        subcategoryName: "beauty & fashion",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/BeautyFashionSC2.svg",
        services: [
          {
            serviceId: "66976bbf45f995d961598e77",
            serviceName: "barber",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/barber.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Barber.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bdab4715f9f92376e9fdd5",
            serviceName: "beautician",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Beautician.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "beauty parlour",
            serviceSortOrder: 4,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesParlour.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9745f995d9615993e3",
            serviceName: "fashion designer",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FashionDesginer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc045f995d961598e7e",
            serviceName: "gents salon",
            serviceSortOrder: 2,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/GentsSalon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 3,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HairDresser.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9945f995d9615993ef",
            serviceName: "laser hair removal",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/laserhairremovalspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/LaserHairRemoval.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "makeup artist",
            serviceSortOrder: 5,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MakeupArtist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehandi designer",
            serviceSortOrder: 6,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bc545f995d961598ea1",
            serviceName: "nail technician",
            serviceSortOrder: 7,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnician.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/NailTechnician.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc645f995d961598ea8",
            serviceName: "skincare specialist",
            serviceSortOrder: 8,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SkinCare.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc745f995d961598eaf",
            serviceName: "unisex salon",
            serviceSortOrder: 9,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/UnisexSalon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bcc45f995d961598ecc",
        subcategoryName: "domestic services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/DomesticSC.svg",
        services: [
          {
            serviceId: "66976bcc45f995d961598ecf",
            serviceName: "bathroom cleaner",
            serviceSortOrder: 13,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Bathroom Cleaner.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcd45f995d961598ed6",
            serviceName: "car washer",
            serviceSortOrder: 14,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/CarWash5.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarWash.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bce45f995d961598edd",
            serviceName: "cleaning services",
            serviceSortOrder: 15,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/CleaningService.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 16,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Cook.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Cook.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd045f995d961598eeb",
            serviceName: "drycleaner",
            serviceSortOrder: 17,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/DryCleaning.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd145f995d961598ef2",
            serviceName: "gardener",
            serviceSortOrder: 18,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/gardener.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Gardner.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b05c2bcf732eb2ecfe8",
            serviceName: "ironing",
            serviceSortOrder: 23,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ironing.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Ironing.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd345f995d961598f00",
            serviceName: "laundry",
            serviceSortOrder: 20,
            isActive: true,
            description: "Fresh clothes, fresher feelings.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Laundry.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Laundry.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd245f995d961598ef9",
            serviceName: "maid/servant",
            serviceSortOrder: 19,
            isActive: true,
            description: "A clean home, a happy heart.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Maid2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MaidServant.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd545f995d961598f07",
            serviceName: "milk man",
            serviceSortOrder: 21,
            isActive: true,
            description: "Farm-fresh milk for a healthier you.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Milkman.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Milkman.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bee45f995d961598fae",
            serviceName: "plumber",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Plumber.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Plumber.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd645f995d961598f0e",
            serviceName: "tailor",
            serviceSortOrder: 22,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tailor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Tailor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd745f995d961598f15",
            serviceName: "water vendor",
            serviceSortOrder: 23,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Water.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bdc45f995d961598f39",
        subcategoryName: "health & wellness",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/HealthWelnessSC.svg",
        services: [
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "chemist/pharmacy",
            serviceSortOrder: 28,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MedicalPharmacy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2b45f995d96159913b",
            serviceName: "optical eye shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Optical.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bdd45f995d961598f42",
        subcategoryName: "maintenance & repair",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/MaintenanceSC.svg",
        services: [
          {
            serviceId: "66976bdf45f995d961598f4c",
            serviceName: "appliance repair",
            serviceSortOrder: 30,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvac.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ApplianceRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9b4415f9f92376e9fdce",
            serviceName: "bike repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BikeRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9be215f9f92376e9fdcf",
            serviceName: "car repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be145f995d961598f5a",
            serviceName: "carpenter",
            serviceSortOrder: 32,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carpenter.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Carpenter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be245f995d961598f61",
            serviceName: "electrician",
            serviceSortOrder: 33,
            isActive: true,
            description: "Brightening your home with expert care.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/Electrician4.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ElectricianIcon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronics shop / repair",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ElectronicShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 35,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GlassAndAlu.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be545f995d961598f76",
            serviceName: "handyman",
            serviceSortOrder: 36,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/handyman.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Handyman.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware shop",
            serviceSortOrder: 37,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Hardware.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bec45f995d961598fa0",
            serviceName: "home painter",
            serviceSortOrder: 42,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/painter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomePainter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be845f995d961598f8b",
            serviceName: "key maker",
            serviceSortOrder: 39,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/keymaker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/KeyMaker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be945f995d961598f92",
            serviceName: "mistri/mason",
            serviceSortOrder: 40,
            isActive: true,
            description: "Building your dreams, brick by brick.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistri.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mistri.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bea45f995d961598f99",
            serviceName: "mobile shop/repair",
            serviceSortOrder: 41,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MobileRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bed45f995d961598fa7",
            serviceName: "pest control",
            serviceSortOrder: 43,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrol.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PestControl.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bee45f995d961598fae",
            serviceName: "plumber",
            serviceSortOrder: 44,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Plumber.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Plumber.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bef45f995d961598fb5",
            serviceName: "roof and shed maker",
            serviceSortOrder: 45,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/RoofAndShed.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf045f995d961598fbc",
            serviceName: "shoe repair",
            serviceSortOrder: 46,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ShoeRepair.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ShoeRepair.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf145f995d961598fc3",
            serviceName: "tyre puncture shop",
            serviceSortOrder: 47,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/TyrePuncture.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf345f995d961598fd1",
            serviceName: "vehicle towing",
            serviceSortOrder: 49,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/VechicleTowing.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop and repair",
            serviceSortOrder: 50,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Watch.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf545f995d961598fdf",
            serviceName: "welder",
            serviceSortOrder: 51,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/welder.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Welder.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bc945f995d961598ebc",
        subcategoryName: "personal care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Care.svg",
        services: [
          {
            serviceId: "66976bca45f995d961598ebf",
            serviceName: "babysitter",
            serviceSortOrder: 11,
            isActive: true,
            description: "Caring for your little ones with love.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/BabySItter.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BabySitter.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bcb45f995d961598ec6",
            serviceName: "elder care",
            serviceSortOrder: 12,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/eldercare.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ElderCare.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cf945f995d96159963f",
            serviceName: "home caretaker",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeCareTaker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976cbf45f995d9615994da",
        subcategoryName: "pet care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/PerCareSC.svg",
        services: [
          {
            serviceId: "66976d2a45f995d961599785",
            serviceName: "animal rescue",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/animalrescue.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/AnimalResuce.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc045f995d9615994dd",
            serviceName: "pet adoption center",
            serviceSortOrder: 262,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/petadoptioncare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PetAdoption.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc145f995d9615994e4",
            serviceName: "pet boarding/shelter",
            serviceSortOrder: 263,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/PetImage3.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetShelter.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66cc28fdbe0fa4ee39921df7",
            serviceName: "pet store",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/petcare.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc445f995d9615994f9",
            serviceName: "pet trainer",
            serviceSortOrder: 266,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pettrainer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetTrainer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "677519bf560464b71da8ac44",
            serviceName: "veterinary clinic",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Veterinary.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "67704b0a5c35d7506d012d81",
        subcategoryName: "Religious",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/Religious3.svg",
        services: [
          {
            serviceId: "66976c5145f995d961599230",
            serviceName: "astrologer",
            serviceSortOrder: 170,
            isActive: true,
            description: "Where the stars align with your story.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Astro.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/AstrologerIcon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d2445f995d96159975f",
            serviceName: "flower shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/florist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/FlowerShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehandi designer",
            serviceSortOrder: 170,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d2845f995d961599777",
            serviceName: "pandit/pujari",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Pandit.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pandit.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Puja.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "676fc0582aa1c89b5ee4a394",
        subcategoryName: "Rentals",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Rent4.svg",
        services: [
          {
            serviceId: "66976bde45f995d961598f45",
            serviceName: "appliances rental",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ApplianceRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0945f995d961599068",
            serviceName: "bike rentals",
            serviceSortOrder: 70,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentals.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BikeRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0c45f995d961599076",
            serviceName: "car rentals",
            serviceSortOrder: 72,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0d45f995d96159907d",
            serviceName: "cycle rentals",
            serviceSortOrder: 73,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/CycleRental.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "67750d0d53b8bfd4b4300b88",
        subcategoryName: "Settling In",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Settling.svg",
        services: [
          {
            serviceId: "677508b5560464b71da8ac42",
            serviceName: "flat and flatmates",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Flatmates.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ca645f995d96159943e",
            serviceName: "movers and packers",
            serviceSortOrder: 170,
            isActive: true,
            description: "Reliable hands for your most precious belongings.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackers.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MoversPackers.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cae45f995d961599472",
            serviceName: "paying guest",
            serviceSortOrder: 243,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/pg.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PayingGuest.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cbf45f995d9615994d4",
            serviceName: "real estate broker",
            serviceSortOrder: 261,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/realestatebroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/RealEstateBroker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cbe45f995d9615994cd",
            serviceName: "rental homes",
            serviceSortOrder: 260,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeonrent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/RentalHomes.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "shopping & retail",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Shopping3.svg",
        services: [
          {
            serviceId: "66976bf645f995d961598fe8",
            serviceName: "atta chakki",
            serviceSortOrder: 52,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimaker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/AttaChakki.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c14be0fa4ee39921dfe",
            serviceName: "bakery/cake shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BakeryCake.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "car accessories / modification",
            serviceSortOrder: 31,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarAccess.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "carpet shop",
            serviceSortOrder: 60,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Carpet3.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0045f995d961599027",
            serviceName: "chicken/meat shop",
            serviceSortOrder: 61,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/meatshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/MeatShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf745f995d961598fef",
            serviceName: "dairy shop",
            serviceSortOrder: 53,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dairyshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/DiaryShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronics shop / repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ElectronicShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 59,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FruitsStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf845f995d961598ff6",
            serviceName: "furniture shop",
            serviceSortOrder: 54,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Furniture.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c82be0fa4ee39921dff",
            serviceName: "general store",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GeneralStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 56,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Grocery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Hardware.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be745f995d961598f84",
            serviceName: "home appliance shop",
            serviceSortOrder: 38,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeAppliance.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5a45f995d96159925a",
            serviceName: "home decor shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/home decor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HomeDecor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 57,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/IceCream.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfd45f995d961599012",
            serviceName: "jewellery shop",
            serviceSortOrder: 58,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Jwellery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 59,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Juice.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/JuiceShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "677a88b8d9a11b06179dc9d7",
            serviceName: "kitchen steel utensils",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/podcaststudioserviceicon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/KitchenSteel.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 62,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Paan2.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Paan.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 63,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Puja.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0345f995d96159903c",
            serviceName: "sports shop",
            serviceSortOrder: 64,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SportsShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0445f995d961599043",
            serviceName: "stationary",
            serviceSortOrder: 65,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stationary.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Stationary.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 66,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hometutor2.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TeaStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toys shop",
            serviceSortOrder: 67,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Toys.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0745f995d961599058",
            serviceName: "vegetable seller",
            serviceSortOrder: 68,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetableseller.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Vegetable.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c0845f995d96159905e",
        subcategoryName: "transport",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Transport.svg",
        services: [
          {
            serviceId: "66976c0845f995d961599061",
            serviceName: "auto/e-rickshaw",
            serviceSortOrder: 69,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Auto3.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0a45f995d96159906f",
            serviceName: "bike-taxi",
            serviceSortOrder: 71,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/BikeTaxiDriver.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0f45f995d96159908b",
            serviceName: "cab/taxi",
            serviceSortOrder: 71,
            isActive: true,
            description: "Safe travels, every time.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Cab.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CabTaxi.svg",
            bgColor: "0XFFFFEABC",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0e45f995d961599084",
            serviceName: "driver/chauffeur",
            serviceSortOrder: 74,
            isActive: true,
            description: "Experience the road with confidence.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/DriverImage.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Chauffeur.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "6770f18afdf4be981860ba3e",
        subcategoryName: "Utilities",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Utilites5.svg",
        services: [
          {
            serviceId: "66976d2e45f995d9615997a1",
            serviceName: "courier services",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/courierservices.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Courier.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3245f995d9615997bb",
            serviceName: "delivery",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Delivery3.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Delivery.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "petrol/gas center",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetrolPump.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3545f995d9615997ce",
            serviceName: "pollution center",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pollutionpucshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pollution.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "676c0d0aaa9c0c0884410d40",
            serviceName: "xerox/photocopy shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/computersuppliesserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Xerox.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "67704efe5c35d7506d012d82",
        subcategoryName: "Zone",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Zone3.svg",
        services: [
          {
            serviceId: "66976bf945f995d961598ffd",
            serviceName: "game center",
            serviceSortOrder: 55,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenter.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/GameCenter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3745f995d9615997dc",
            serviceName: "sports arena",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsarena.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/SportsArena.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c9145f995d9615993bf",
    categoryName: "food & beverage",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/FoodBeverageC.svg",
    subcategories: [
      {
        subcategoryId: "66976bd745f995d961598f1b",
        subcategoryName: "food & beverage",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/FoodB2.svg",
        services: [
          {
            serviceId: "677022b0fdf4be981860ba38",
            serviceName: "bar and lounge",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Bar4.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd845f995d961598f1e",
            serviceName: "cafe",
            serviceSortOrder: 210,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cafe.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Cafe.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FruitsStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd945f995d961598f25",
            serviceName: "homemade food",
            serviceSortOrder: 212,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefood.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeMadeFood.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/IceCream.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 170,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Juice.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/JuiceShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Paan2.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Paan.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bda45f995d961598f2c",
            serviceName: "restaurant",
            serviceSortOrder: 213,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Res12.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Restaurant.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bdb45f995d961598f33",
            serviceName: "street food",
            serviceSortOrder: 214,
            isActive: true,
            description: "Bite into the soul of the city.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/StreetFood2.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/StreetFood.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c4545f995d9615991dc",
            serviceName: "sweets shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/sweetshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SweetShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hometutor2.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TeaStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c4e45f995d96159921c",
    categoryName: "professional services",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/ProfessionC.svg",
    subcategories: [
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976c4f45f995d961599222",
            serviceName: "accountant",
            serviceSortOrder: 144,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/accountant.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Accoutant.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5045f995d961599229",
            serviceName: "architect",
            serviceSortOrder: 145,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/architect.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Architect.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5145f995d961599230",
            serviceName: "astrologer",
            serviceSortOrder: 146,
            isActive: true,
            description: "Where the stars align with your story.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Astro.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/AstrologerIcon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c7045f995d9615992f1",
            serviceName: "balloon decorator",
            serviceSortOrder: 174,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecorator.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Balloon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7145f995d9615992f8",
            serviceName: "birthday planner",
            serviceSortOrder: 175,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/birthdayplanner.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Birthday.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc297bbe0fa4ee39921df8",
            serviceName: "car/bike dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/CarBikeDealer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5345f995d961599237",
            serviceName: "catering service",
            serviceSortOrder: 147,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringservice.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Catering.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5445f995d96159923e",
            serviceName: "chartered accountant",
            serviceSortOrder: 148,
            isActive: true,
            description: "Your trusted partner in financial success.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/CA.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CA.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bda32e15f9f92376e9fdd1",
            serviceName: "content creator",
            serviceSortOrder: 174,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ContentCreator.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2e45f995d9615997a1",
            serviceName: "courier services",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/courierservices.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Courier.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b0fbe0fa4ee39921dfa",
            serviceName: "daily wage worker",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Worker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d3552e8c69575453cb65ff",
            serviceName: "detective agency",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/detectiveagency.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/DetectiveAgency.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5545f995d961599245",
            serviceName: "digital marketing consultant",
            serviceSortOrder: 149,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/DigitalMarketing.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7245f995d9615992ff",
            serviceName: "dj Disc Jockey",
            serviceSortOrder: 176,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dj.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/DJ.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7545f995d96159930d",
            serviceName: "event planner/manager",
            serviceSortOrder: 178,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/eventplanner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/EventManager.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356f28c69575453cb6602",
            serviceName: "event tent dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tentshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/EventTent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bee60f170ead650c3c28d3",
            serviceName: "financial consultant",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/accountant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FinancialConsultant.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bedd4b170ead650c3c28d2",
            serviceName: "fire fighting vendor",
            serviceSortOrder: 150,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FireFighting.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6698a9c864a85a0fa65b4631",
            serviceName: "firecracker factory",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/firecrackerfactory.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FireCracker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d357588c69575453cb6603",
            serviceName: "forex",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/forex.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Forex.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5945f995d961599253",
            serviceName: "graphic designer",
            serviceSortOrder: 151,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/graphicdesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GraphicDesigner.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0145f995d961599679",
            serviceName: "gym trainer",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/GymTrainer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6776ca621479fb316ce5f206",
            serviceName: "home automation",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeAutomation.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2bd9be0fa4ee39921dfd",
            serviceName: "home builder",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeBuilder.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5d45f995d96159926f",
            serviceName: "home interiors",
            serviceSortOrder: 155,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordecorator.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeInteriors.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bda93b15f9f92376e9fdd3",
            serviceName: "house keeping",
            serviceSortOrder: 153,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HouseKeeping.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bda35515f9f92376e9fdd2",
            serviceName: "influencer",
            serviceSortOrder: 177,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Influencer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5c45f995d961599268",
            serviceName: "insurance agent",
            serviceSortOrder: 154,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/insurancebroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/InsuranceAgent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5e45f995d961599276",
            serviceName: "interior designer",
            serviceSortOrder: 156,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5f45f995d96159927d",
            serviceName: "it hardware and rentals",
            serviceSortOrder: 157,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/itsupport.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ITHardware.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6045f995d961599284",
            serviceName: "lawyer",
            serviceSortOrder: 158,
            isActive: true,
            description: "Guiding you through the legal maze.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Lawyer.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Lawyer.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c6145f995d96159928b",
            serviceName: "legal consultant",
            serviceSortOrder: 159,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/legalconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/LegalConsultant.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d357bd8c69575453cb6604",
            serviceName: "loan/finance",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/loanfinance.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Finance.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd02b9993052292902c094",
            serviceName: "media publications",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MediaPublications.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2b45f995d96159978c",
            serviceName: "merchandise vendor",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/merchandisevendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Merchandise.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ca645f995d96159943e",
            serviceName: "movers and packers",
            serviceSortOrder: 170,
            isActive: true,
            description: "Reliable hands for your most precious belongings.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackers.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MoversPackers.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7745f995d96159931b",
            serviceName: "music band",
            serviceSortOrder: 180,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/musicband.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/MusicBand.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7a45f995d961599330",
            serviceName: "music studio",
            serviceSortOrder: 183,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/recordingmusicstudio.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MusicStudio.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6245f995d961599292",
            serviceName: "mutual fund broker",
            serviceSortOrder: 160,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mutualfundbroker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/MutualFund.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6345f995d961599299",
            serviceName: "notary shop",
            serviceSortOrder: 161,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/notaryshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/NotaryShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6f45f995d9615992ea",
            serviceName: "painter/sketcher",
            serviceSortOrder: 173,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/artist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Painter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7845f995d961599322",
            serviceName: "party hall",
            serviceSortOrder: 181,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/partyhall.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PartyHall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6445f995d9615992a0",
            serviceName: "personal bodyguard",
            serviceSortOrder: 162,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/personalbodyguard.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Bodyguard.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7945f995d961599329",
            serviceName: "photographer/videographer",
            serviceSortOrder: 182,
            isActive: true,
            description: "Where every click tells a story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/PhotographerImage.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Photographer.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "6745bc27cad6997f158b404a",
            serviceName: "podcast studio",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/podcaststudioserviceicon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PodcastStudio.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3645f995d9615997d5",
            serviceName: "printing press",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/printingpress.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PrintingPress.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b19c2bcf732eb2ecfe9",
            serviceName: "scraper",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/scraper.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Scraper.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6545f995d9615992a7",
            serviceName: "security guard",
            serviceSortOrder: 163,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/securityguard.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/SecurityGuard.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6945f995d9615992bc",
            serviceName: "solar panel agency",
            serviceSortOrder: 166,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/solarpanelinstaller.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SolarPanel.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7b45f995d961599337",
            serviceName: "standup artist",
            serviceSortOrder: 184,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/standupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/StandupArtist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6b45f995d9615992ca",
            serviceName: "stock broker",
            serviceSortOrder: 168,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stockinvestorbroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/StockBroker.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7c45f995d96159933e",
            serviceName: "tattoo artist",
            serviceSortOrder: 185,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooartist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Tattoo.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6745bceacad6997f158b404c",
            serviceName: "telecommunications",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/telecommunicationserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TeleComm.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356af8c69575453cb6601",
            serviceName: "visa agent",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/visaassistance.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/VisaAgent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9c5415f9f92376e9fdd0",
            serviceName: "watchman",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Watchman.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976cf945f995d961599644",
    categoryName: "health & wellness",
    categoryImage:
      "https://d2uvzz1jka6usw.cloudfront.net/HealthAndWelnessC.svg",
    subcategories: [
      {
        subcategoryId: "66976bdc45f995d961598f39",
        subcategoryName: "health & wellness",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/HealthWelnessSC.svg",
        services: [
          {
            serviceId: "66976cfb45f995d96159964f",
            serviceName: "acupuncturist",
            serviceSortOrder: 326,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/acupuncturist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Acupuncturists.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356428c69575453cb6600",
            serviceName: "ambulance",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ambulance.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Ambulance.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "67710d9d69bca30ec6ac4385",
            serviceName: "ayurvedic panchkarma",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Panchkarma.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfa45f995d961599648",
            serviceName: "blood test lab",
            serviceSortOrder: 325,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bloodtestlab.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BloodTest.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "chemist/pharmacy",
            serviceSortOrder: 342,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MedicalPharmacy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfc45f995d961599656",
            serviceName: "chiropractor",
            serviceSortOrder: 327,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/chiropractor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Chiropractor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfd45f995d96159965d",
            serviceName: "dentist",
            serviceSortOrder: 328,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dentist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dentistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfe45f995d961599664",
            serviceName: "dermatologist",
            serviceSortOrder: 329,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/dermatologist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Dermatologist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cff45f995d96159966b",
            serviceName: "dietitian",
            serviceSortOrder: 330,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dietitian.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Dietician.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0045f995d961599672",
            serviceName: "doctor",
            serviceSortOrder: 331,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/doctor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Doctor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2ba3be0fa4ee39921dfc",
            serviceName: "gym",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Gym.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0145f995d961599679",
            serviceName: "gym trainer",
            serviceSortOrder: 332,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/GymTrainer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6745bcb5cad6997f158b404b",
            serviceName: "gynaecologist",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gynaecologistserviceicon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Gynaecologist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0245f995d961599680",
            serviceName: "herbalist",
            serviceSortOrder: 333,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/herbalist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Herbalist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "67710fbc69bca30ec6ac4386",
            serviceName: "homeopathy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Homeopathy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0345f995d961599687",
            serviceName: "massage therapist",
            serviceSortOrder: 334,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/massagetherapist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Massage.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0645f995d961599695",
            serviceName: "occupational therapist",
            serviceSortOrder: 336,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/occupationaltherapist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Occupational.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2b45f995d96159913b",
            serviceName: "optical eye shop",
            serviceSortOrder: 343,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Optical.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0845f995d9615996a3",
            serviceName: "physiotherapist",
            serviceSortOrder: 338,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/physiotherapist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Physio.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0a45f995d9615996b1",
            serviceName: "psychiatrist",
            serviceSortOrder: 340,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/psychiatrist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Psychiatrist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6a45f995d9615992c3",
            serviceName: "speech therapist",
            serviceSortOrder: 167,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/speechtherapist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Speech.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "677519bf560464b71da8ac44",
            serviceName: "veterinary clinic",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Veterinary.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0b45f995d9615996b8",
            serviceName: "yoga trainer",
            serviceSortOrder: 341,
            isActive: true,
            description: "Transform your life through yoga.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Yoga.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Yoga.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c1b45f995d9615990d7",
    categoryName: "shopping & retail",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/ShoopingC.svg",
    subcategories: [
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "shopping & retail",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Shopping3.svg",
        services: [
          {
            serviceId: "66d351d88c69575453cb65fd",
            serviceName: "agricultural pesticide dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pesticide.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d351898c69575453cb65fc",
            serviceName: "agricultural tool dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/AgriTool.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf645f995d961598fe8",
            serviceName: "atta chakki",
            serviceSortOrder: 89,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimaker.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/AttaChakki.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c14be0fa4ee39921dfe",
            serviceName: "bakery/cake shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BakeryCake.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "car accessories / modification",
            serviceSortOrder: 90,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/CarAccess.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "carpet shop",
            serviceSortOrder: 104,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Carpet3.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "chemist/pharmacy",
            serviceSortOrder: 106,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MedicalPharmacy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0045f995d961599027",
            serviceName: "chicken/meat shop",
            serviceSortOrder: 105,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/meatshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/MeatShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "67705dd00141fa13db3898f3",
            serviceName: "clothes and garments shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Clothes.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf745f995d961598fef",
            serviceName: "dairy shop",
            serviceSortOrder: 92,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dairyshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/DiaryShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2f45f995d9615997a8",
            serviceName: "disposable distributor",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/dispobaledistributor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Disposable.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronics shop / repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ElectronicShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3045f995d9615997af",
            serviceName: "flex and board printing",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/flexprintingpress.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Flex.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 102,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FruitsStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf845f995d961598ff6",
            serviceName: "furniture shop",
            serviceSortOrder: 93,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Furniture.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c82be0fa4ee39921dff",
            serviceName: "general store",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GeneralStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d548ce279fd41f77d4aa6e",
            serviceName: "gift shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/giftshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Gift.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 97,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GlassAndAlu.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 98,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Grocery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware shop",
            serviceSortOrder: 99,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Hardware.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be745f995d961598f84",
            serviceName: "home appliance shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HomeAppliance.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5a45f995d96159925a",
            serviceName: "home decor shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/home decor.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HomeDecor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d54767279fd41f77d4aa6c",
            serviceName: "home made pickle seller",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadepickleseller.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pickle.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 100,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/IceCream.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5f45f995d96159927d",
            serviceName: "it hardware and rentals",
            serviceSortOrder: 100,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/itsupport.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ITHardware.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfd45f995d961599012",
            serviceName: "jewellery shop",
            serviceSortOrder: 101,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Jwellery.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 102,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Juice.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/JuiceShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "677a88b8d9a11b06179dc9d7",
            serviceName: "kitchen steel utensils",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/podcaststudioserviceicon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/KitchenSteel.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7645f995d961599314",
            serviceName: "lighting store",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/lightingspecialist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Light.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2b45f995d96159978c",
            serviceName: "merchandise vendor",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/merchandisevendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Merchandise.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2b45f995d96159913b",
            serviceName: "optical eye shop",
            serviceSortOrder: 107,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Optical.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 108,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Paan2.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Paan.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66cc2a85be0fa4ee39921df9",
            serviceName: "perfume shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Perfume.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc28fdbe0fa4ee39921df7",
            serviceName: "pet store",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/petcare.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetStore.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "petrol/gas center",
            serviceSortOrder: 95,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PetrolPump.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 109,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Puja.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6771080169bca30ec6ac4384",
            serviceName: "shoes/footwear shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ShoeShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0345f995d96159903c",
            serviceName: "sports shop",
            serviceSortOrder: 110,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SportsShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0445f995d961599043",
            serviceName: "stationary",
            serviceSortOrder: 111,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stationary.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Stationary.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d5c41ca03a79e993d1e1c8",
            serviceName: "sweets box manufacturer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sweetsboxmanufacturer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SweetsBox.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 112,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hometutor2.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TeaStall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toys shop",
            serviceSortOrder: 113,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Toys.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6771069169bca30ec6ac4383",
            serviceName: "travel and accessory shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/barandlounge.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/TravelShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0745f995d961599058",
            serviceName: "vegetable seller",
            serviceSortOrder: 115,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetableseller.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Vegetable.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop and repair",
            serviceSortOrder: 116,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Watch.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "676c0d0aaa9c0c0884410d40",
            serviceName: "xerox/photocopy shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/computersuppliesserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Xerox.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c9645f995d9615993da",
    categoryName: "beauty & fashion",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/BeautyFashionC.svg",
    subcategories: [
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976bbf45f995d961598e77",
            serviceName: "barber",
            serviceSortOrder: 215,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/barber.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Barber.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bdab4715f9f92376e9fdd5",
            serviceName: "beautician",
            serviceSortOrder: 216,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Beautician.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "beauty parlour",
            serviceSortOrder: 225,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesParlour.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9745f995d9615993e3",
            serviceName: "fashion designer",
            serviceSortOrder: 216,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FashionDesginer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc045f995d961598e7e",
            serviceName: "gents salon",
            serviceSortOrder: 224,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/GentsSalon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 217,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HairDresser.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9945f995d9615993ef",
            serviceName: "laser hair removal",
            serviceSortOrder: 218,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/laserhairremovalspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/LaserHairRemoval.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "makeup artist",
            serviceSortOrder: 219,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MakeupArtist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehandi designer",
            serviceSortOrder: 220,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bc545f995d961598ea1",
            serviceName: "nail technician",
            serviceSortOrder: 221,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnician.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/NailTechnician.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc645f995d961598ea8",
            serviceName: "skincare specialist",
            serviceSortOrder: 222,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/SkinCare.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc745f995d961598eaf",
            serviceName: "unisex salon",
            serviceSortOrder: 226,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/UnisexSalon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "676fd9d67e79947c2f0bf1ee",
    categoryName: "Education & Training",
    categoryImage:
      "https://d2uvzz1jka6usw.cloudfront.net/EducationCategory.svg",
    subcategories: [
      {
        subcategoryId: "66976cdc45f995d96159958b",
        subcategoryName: "education & training",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/educationtraining.svg",
        services: [
          {
            serviceId: "66bc4efc098c25fc9199e88d",
            serviceName: "architecture academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/architectureacademy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ArchitectureAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee6098c25fc9199e7fd",
            serviceName: "art academy/teacher",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/art academy.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/ArtAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f02098c25fc9199e8b5",
            serviceName: "astronomy academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/astronomy academy.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Astronomy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cde45f995d961599595",
            serviceName: "bakery classes",
            serviceSortOrder: 296,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/BakeryCake.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef3098c25fc9199e855",
            serviceName: "coding academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/coding/programming academy.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Coding.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cdf45f995d96159959c",
            serviceName: "cooking classes",
            serviceSortOrder: 297,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cookingclassteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/CookingClasses.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce045f995d9615995a3",
            serviceName: "dance academy/teacher",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/danceacademy.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Dance.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d352ad8c69575453cb65fe",
            serviceName: "driving school",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/DrivingSchool.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f01098c25fc9199e8ad",
            serviceName: "engineering academy/teacher",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/engineering academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Engineering Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f07098c25fc9199e8d5",
            serviceName: "environmental studies academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/environmental studies academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Environmental Studies Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f04098c25fc9199e8c5",
            serviceName: "etiquette Institute",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/etiquette academy.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Etiquette.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef0098c25fc9199e83d",
            serviceName: "fashion design institute",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashion design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FashionDesign.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eed098c25fc9199e82d",
            serviceName: "film/acting academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/film/acting academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FilmActing Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef8098c25fc9199e875",
            serviceName: "graphic design institute",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/graphic design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Graphic Design Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce345f995d9615995b8",
            serviceName: "high school teacher",
            serviceSortOrder: 301,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/highschoolteacher.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HSTutor.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce445f995d9615995bf",
            serviceName: "home tutor",
            serviceSortOrder: 302,
            isActive: true,
            description: "Where learning meets personalized attention.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/HomeTutor.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HomeTutor.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efd098c25fc9199e895",
            serviceName: "interior design institute",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interior design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/InteriorDesignInstitute.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eea098c25fc9199e815",
            serviceName: "language academy/tutor",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/language academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/LanguageAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef5098c25fc9199e85d",
            serviceName: "leadership academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/business/entrepreneurship academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Leadership Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee7098c25fc9199e805",
            serviceName: "martial arts classes",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/martial arts academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Martial Arts.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef7098c25fc9199e86d",
            serviceName: "math academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mathematics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MathsAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce645f995d9615995cd",
            serviceName: "medical teacher",
            serviceSortOrder: 304,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MedicalTeacher.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee5098c25fc9199e7f5",
            serviceName: "music classes/academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MusicAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eff098c25fc9199e8a5",
            serviceName: "music production academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music production academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Music Production Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce745f995d9615995d4",
            serviceName: "music teacher",
            serviceSortOrder: 305,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/musicteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MusicTeacher.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eef098c25fc9199e835",
            serviceName: "photography classes/academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/photography academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PhotographyAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce845f995d9615995db",
            serviceName: "play school",
            serviceSortOrder: 306,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/playschool.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PlaySchool.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce945f995d9615995e2",
            serviceName: "pre nursery teacher",
            serviceSortOrder: 307,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/prenurseryteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PreNurseryTeacher.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cea45f995d9615995e9",
            serviceName: "primary teacher",
            serviceSortOrder: 308,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/primaryteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PrimaryTeacher.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef9098c25fc9199e87d",
            serviceName: "public speaking classes",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/public speaking/debate academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/PublicSpeaking.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f03098c25fc9199e8bd",
            serviceName: "robotics academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/robotics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Robotics Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef6098c25fc9199e865",
            serviceName: "science academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/science academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Science Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eeb098c25fc9199e81d",
            serviceName: "sports academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsacademy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/SportsAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cec45f995d9615995f0",
            serviceName: "sports trainer",
            serviceSortOrder: 309,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportstrainer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/SportsTrainer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eec098c25fc9199e825",
            serviceName: "theater/drama academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/theater/drama Academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/TheaterAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "677aa04fd9a11b06179dc9d8",
            serviceName: "upsc coaching",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/podcaststudioserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/upsc.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef2098c25fc9199e84d",
            serviceName: "writing academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/writing/creative writing academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Writing Academy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efb098c25fc9199e885",
            serviceName: "yoga/meditation academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/yoga/meditation academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/YogaAcademy.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976d0d45f995d9615996c7",
    categoryName: "social cause",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/socialcause2.svg",
    subcategories: [
      {
        subcategoryId: "66976d0f45f995d9615996d3",
        subcategoryName: "blood donation",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/blooddonation.svg",
        services: [
          {
            serviceId: "66976d0f45f995d9615996d6",
            serviceName: "blood donation camp",
            serviceSortOrder: 345,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/blooddonationcamp.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/BloodDonation.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1245f995d9615996e8",
            serviceName: "donation camp",
            serviceSortOrder: 347,
            isActive: true,
            description: "Give a little, change a lot.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Donation.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/DonationCamp.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d1345f995d9615996f1",
            serviceName: "environmental program",
            serviceSortOrder: 348,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/environmentalprogram.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Environment.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1145f995d9615996df",
            serviceName: "group charity",
            serviceSortOrder: 346,
            isActive: true,
            description:
              "Join loved ones, share and support meaningful causes together.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/charity.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/GroupCharity.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
          {
            serviceId: "66976d1445f995d9615996f8",
            serviceName: "health and wellness camp",
            serviceSortOrder: 349,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/healthwellnesscamp.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/HealthCamp.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1545f995d961599701",
            serviceName: "ngo",
            serviceSortOrder: 350,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ngo.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/NGO.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0e45f995d9615996cd",
            serviceName: "social awareness campaign",
            serviceSortOrder: 344,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/socialawarenesscampaign.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/SocialAwareness.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976d1645f995d961599706",
    categoryName: "marriage",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/MarriageC.svg",
    subcategories: [
      {
        subcategoryId: "66976bbf45f995d961598e74",
        subcategoryName: "beauty & fashion",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/BeautyFashionSC2.svg",
        services: [
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "beauty parlour",
            serviceSortOrder: 353,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesParlour.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9745f995d9615993e3",
            serviceName: "fashion designer",
            serviceSortOrder: 351,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/FashionDesginer.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 352,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/HairDresser.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "makeup artist",
            serviceSortOrder: 354,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MakeupArtist.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehandi designer",
            serviceSortOrder: 355,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Mehandi.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c6e45f995d9615992e0",
        subcategoryName: "creative & entertainment",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/creativeentertainment.svg",
        services: [
          {
            serviceId: "66976c7045f995d9615992f1",
            serviceName: "balloon decorator",
            serviceSortOrder: 357,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecorator.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Balloon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7245f995d9615992ff",
            serviceName: "dj Disc Jockey",
            serviceSortOrder: 358,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dj.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/DJ.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7745f995d96159931b",
            serviceName: "music band",
            serviceSortOrder: 361,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/musicband.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/MusicBand.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6f45f995d9615992ea",
            serviceName: "painter/sketcher",
            serviceSortOrder: 356,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/artist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Painter.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7845f995d961599322",
            serviceName: "party hall",
            serviceSortOrder: 362,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/partyhall.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/PartyHall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7945f995d961599329",
            serviceName: "photographer/videographer",
            serviceSortOrder: 363,
            isActive: true,
            description: "Where every click tells a story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/PhotographerImage.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/Photographer.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976d2245f995d96159974c",
        subcategoryName: "find partner",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/findpartner.svg",
        services: [
          {
            serviceId: "66976d2245f995d96159974f",
            serviceName: "matrimony",
            serviceSortOrder: 364,
            isActive: true,
            description: "Find your match through close connections.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/findgroomandbrideserviceicon.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Martimony.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
        ],
      },
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976c5145f995d961599230",
            serviceName: "astrologer",
            serviceSortOrder: 367,
            isActive: true,
            description: "Where the stars align with your story.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Astro.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/AstrologerIcon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66d3586c8c69575453cb6605",
            serviceName: "banquet hall",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/banquethall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/BanquetHall.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5345f995d961599237",
            serviceName: "catering service",
            serviceSortOrder: 368,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringservice.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Catering.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356f28c69575453cb6602",
            serviceName: "event tent dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tentshop.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/EventTent.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2845f995d961599777",
            serviceName: "pandit/pujari",
            serviceSortOrder: 370,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/Pandit.png",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Pandit.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d2745f995d961599770",
            serviceName: "wedding planner",
            serviceSortOrder: 369,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/marriageplanner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/MarriagePlanner.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "shopping & retail",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/Shopping3.svg",
        services: [
          {
            serviceId: "66976d2445f995d96159975f",
            serviceName: "flower shop",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/florist.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/FlowerShop.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 371,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/Puja.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
];

export const blogsSEOData = [
  {
    id: 1,
    title:
      "Bridging the Digital Divide: Bataiyo’s Pre-Registration Initiative Launching Nationwide on August 15th",
    tags: ["Innovation"],
    description:
      "Explore how Bataiyo is working to bridge the digital divide with its nationwide pre-registration initiative launching on August 15th.",
    keywords:
      "Digital Divide, Bataiyo, Pre-Registration, Nationwide Launch, Innovation",
  },
  {
    id: 2,
    title:
      "Supporting Local Businesses Through Recommendations: The Power of Community",
    tags: ["Social Impact"],
    description:
      "Learn how community-driven recommendations are supporting local businesses and fostering a stronger community.",
    keywords: "Local Businesses, Community, Recommendations, Social Impact",
  },
  {
    id: 3,
    title:
      "How Trustworthy Are Modern Day Reviews on Google, MakeMyTrip, and Other Platforms? Why Friends’ Recommendations Still Reign Supreme",
    tags: ["Trust and Credibility"],
    description:
      "Dive into the reliability of online reviews on platforms like Google and MakeMyTrip, and why personal recommendations still hold value.",
    keywords: "Online Reviews, Trust, Credibility, Google, MakeMyTrip",
  },
  {
    id: 4,
    title:
      "Discovering Chandigarh: A Modern Day of Exploration and Culinary Delights",
    tags: ["Travel"],
    description:
      "Join us on a journey through Chandigarh, exploring its modern attractions and savoring its culinary delights.",
    keywords: "Chandigarh, Travel, Exploration, Culinary Delights",
  },
  {
    id: 5,
    title: "Moving to a New City: Navigating Challenges and Finding Solutions",
    tags: ["City Living"],
    description:
      "Moving to a new city? Discover practical solutions to common challenges and make your transition smoother.",
    keywords: "Moving, New City, Challenges, Solutions, City Living",
  },
];
