import { useEffect, useState } from "react";

function useScroll() {
  const [initialLoading, setInitialLoading] = useState(true);

  /* This `useEffect` hook is performing several tasks related to scrolling behavior and attribute manipulation in a React component.*/
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });
    // Function to add the attribute to the element
    const addAttribute = () => {
      const elements = [
        { className: ".MuiDialog-container", name: "popover" },
        { className: ".MuiAutocomplete-popper", name: "selectorPopover" },
        { className: ".MuiDrawer-root", name: "navDrawer" },
        { className: ".MuiPickersPopper-paper", name: "datePicker" },
      ];
      elements.forEach((element) => {
        const el = document.querySelector(element.className);
        if (el) {
          switch (element.name) {
            case "popover":
            case "selectorPopover":
            case "navDrawer":
            case "datePicker":
              el.setAttribute("data-lenis-prevent", "");
              break;
            default:
              console.warn(`No action defined for ${element.name}`);
          }
        }
      });
    };

    // Create a MutationObserver instance
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          addAttribute();
        }
      }
    });

    // Start observing the body for changes
    observer.observe(document.body, { childList: true, subtree: true });
    setTimeout(() => {
      setInitialLoading(false);
    }, 300);

    // Cleanup
    return () => {
      if (scroll) scroll.destroy();
      observer.disconnect();
    };
  }, []);

  return { initialLoading };
}

export default useScroll;
