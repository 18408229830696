import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { Drawer } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLogo } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { navItems } from "../../constant/staticData";
import { useDialog } from "../../context/DialogContext";
import SignUpForm from "../forms/SignUpForm";
import CustomDialog from "../SignUpFormVerification/CustomDialog";
import "./Navbar.css";
const Navbar = () => {
  const { handleDialogOpen } = useDialog();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeHash, setActiveHash] = useState(location.hash);
  const [handleSuccessDialogOpen, setHandleSuccessDialogOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // Get the token from the query parameters
    const token = searchParams.get("token");
    if (
      location.pathname === "/register" &&
      token === ROUTES.REGISTRATION_TOKEN
    ) {
      handleDialogOpen();
    }
  }, [location, handleDialogOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const navbarHeight =
            document.querySelector(".navbar")?.offsetHeight || 0;
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - navbarHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    };

    // Delay the scroll to ensure DOM is fully updated
    setTimeout(scrollToHash, 300);
  }, [location.pathname]);

  const handleNavItemClick = (path) => {
    setIsDrawerOpen(false);
    if (path.startsWith("/#")) {
      const sectionId = path.substring(2);
      const element = document.getElementById(sectionId);
      if (element) {
        const navbarHeight =
          document.querySelector(".navbar")?.offsetHeight || 0;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - navbarHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    } else {
      window.location.href = path;
    }
  };

  useEffect(() => {
    setActiveHash(location.hash);
  }, [location.hash]);

  const drawerList = (
    <div className="mob-line-icon">
      <div className="mob-nav-heading-box">
        <a href={`${ROUTES.HOME}#hero`} onClick={toggleDrawer(false)}>
          <img className="mob-nav-bataiyo-logo" src={NavLogo} alt="bataiyo" />{" "}
        </a>
        <div className="nav-mobile-screen">
          <CloseIcon
            onClick={toggleDrawer(false)}
            className="drawer-white-icon"
          />
        </div>
      </div>
      <div className="mob-nav-list-box">
        <div className="mob-nav-hash-ids">
          {navItems?.map((item) => (
            <React.Fragment key={item?.id}>
              {item?.path ? (
                <a
                  href={`/${item?.path}`}
                  onClick={() => handleNavItemClick(`/${item?.path}`)}
                  className={`mob-nav-item-text ${
                    location.pathname === `/${item?.path}` ||
                    activeHash === `${item?.path}`
                      ? "active-nav-link"
                      : ""
                  }`}
                >
                  {item?.name}
                </a>
              ) : (
                <Link
                  className={`mob-nav-item-text ${
                    location.pathname === `${item?.route}` && "active-nav-link"
                  }`}
                  to={item?.route}
                >
                  {item?.name}
                </Link>
              )}
            </React.Fragment>
          ))}
          <button
            className="hero-download-btn"
            onClick={() => {
              setHandleSuccessDialogOpen(true);
              setIsDrawerOpen(false);
            }}
          >
            Download App
          </button>
        </div>
        <div className="mob-nav-horizontal-line" />
        <div className="mob-nav-social-icons-box">
          <div className="mob-nav-follow-text">FOLLOW US:</div>
          <div className="mob-nav-soc-link-box">
            <Link
              to={ROUTES.LINKEDIN_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              LinkedIn <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.YOUTUBE_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              YouTube <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.TWITTER_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              X (Twitter) <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.FACEBOOK_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              Facebook <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.INSTAGRAM_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              Instagram <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <nav className={`navbar ${scrolled ? "scrolled" : ""}`} id="nav">
        <div className="nav-inner-box">
          <div>
            <a href={`${ROUTES.HOME}#hero`} className="logo-a-tag">
              <img
                src={NavLogo}
                alt="bataiyo-logo"
                className="bataiyo-logo-img"
                loading="lazy"
              />
            </a>
          </div>
          <div className="nav-mobile-screen">
            <DensityMediumIcon
              onClick={toggleDrawer(true)}
              className="drawer-white-icon"
            />
          </div>
          <div className="nav-items">
            {navItems?.map((item) => (
              <div key={item?.id}>
                {item?.path ? (
                  <a
                    className={`nav-item-text ${
                      location.pathname === `/${item?.path}` ||
                      activeHash === `${item?.path}`
                        ? "active-nav-item-link"
                        : ""
                    }`}
                    href={`/${item?.path}`}
                    onClick={() => handleNavItemClick(`/${item?.path}`)}
                  >
                    {item?.name}
                  </a>
                ) : (
                  <Link
                    className={`nav-item-text ${
                      location.pathname === `${item?.route}`
                        ? "active-nav-item-link"
                        : ""
                    }`}
                    to={item?.route}
                  >
                    {item?.name}
                  </Link>
                )}
              </div>
            ))}
            <button
              className="hero-download-btn"
              onClick={() => setHandleSuccessDialogOpen(true)}
            >
              Download App
            </button>
          </div>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            sx={{
              "& .MuiDrawer-paper": {
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                boxSizing: "border-box",
                paddingBottom: "env(safe-area-inset-bottom)",
              },
            }}
          >
            {drawerList}
          </Drawer>
        </div>
      </nav>
      <SignUpForm />
      {handleDialogOpen && (
        <CustomDialog
          isDialogOpen={handleSuccessDialogOpen}
          handleClose={() => setHandleSuccessDialogOpen(false)}
        />
      )}
    </>
  );
};

export default memo(Navbar);
