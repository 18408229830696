import React from "react";
import {
  servicesExperiences,
  usersExperiences,
} from "../../constant/staticData";
import "./Experience.css";

function Experience() {
  return (
    <div className="experience-container" id="experience">
      <h1 className="experience-heading">Experience</h1>
      <div className="experience-content-box">
        <div className="experience-inner-box">
          <div className="experience-explorer-text">Explorer</div>
          <div className="experience-list">
            {usersExperiences?.map((item) => (
              <div className="experience-options" key={item?.id}>
                <div className="experience-icon-container">
                  <img
                    src={item?.img}
                    alt={item?.alt}
                    loading="lazy"
                    className="experience-icon"
                  />{" "}
                </div>
                <div className="experience-options-desc-box">
                  <div className="experience-options-heading">
                    {item?.title}
                  </div>
                  <div className="experience-options-description">
                    {item?.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="provider-exp-inner-box">
          <div className="experience-provider-text">Service Provider</div>
          <div className="experience-list">
            {servicesExperiences?.map((item) => (
              <div className="experience-options" key={item?.id}>
                <div className="experience-icon-container">
                  <img
                    src={item?.img}
                    alt={item?.alt}
                    loading="lazy"
                    className="experience-icon"
                  />{" "}
                </div>
                <div className="experience-options-desc-box">
                  <div className="experience-options-heading">
                    {item?.title}
                  </div>
                  <div className="experience-options-description">
                    {item?.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
