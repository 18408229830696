import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ERROR_MESSAGES } from "../../constant/messages";
import { getFAQs } from "../../services/landingPage.service";
import FAQAccordion from "./FAQAccordion";
import "./FAQSection.css";
import { TabsStyle } from "./styleSheet";

function FAQSection() {
  const [faqData, setFaqData] = useState({
    commonFAQsData: [],
    usersFAQsData: [],
    providerFAQsData: [],
  });
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const faqRef = useRef(null);
  const [isFAQVisible, setIsFAQVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsFAQVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = faqRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [faqRef]);

  useEffect(() => {
    if (!isFAQVisible) return;

    const getData = async () => {
      try {
        const response = await getFAQs();
        const transformedData = {
          commonFAQsData: [],
          usersFAQsData: [],
          providerFAQsData: [],
        };
        response?.data?.result?.forEach((category) => {
          switch (category?.faqCategory) {
            case "Common":
              transformedData.commonFAQsData = category?.qaPairs;
              break;
            case "User":
              transformedData.usersFAQsData = category?.qaPairs;
              break;
            case "Service":
              transformedData.providerFAQsData = category?.qaPairs;
              break;
            default:
              break;
          }
        });

        setFaqData(transformedData);
        setTimeout(() => setLoading(false), 1000);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };

    getData();
  }, [isFAQVisible]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {faqData && !error && (
        <section className="faq-section-container" id="faq" ref={faqRef}>
          <h2 className="faq-heading">FAQ</h2>
          <div className="faq-tabs-box">
            <div className="faq-tabs-inner-box">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="FAQ Categories"
                centered
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                sx={TabsStyle}
              >
                <Tab label="Common" />
                <Tab label="Users" />
                <Tab label="Services" />
              </Tabs>
            </div>

            {loading ? (
              // Render skeletons while loading
              <Box sx={{ px: 2, py: 3, width: "100%" }}>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={80}
                    sx={{ mt: index === 0 ? 0 : 2 }}
                  />
                ))}
              </Box>
            ) : (
              // Render actual FAQs
              <>
                {activeTab === 0 && (
                  <FAQAccordion data={faqData?.commonFAQsData} />
                )}
                {activeTab === 1 && (
                  <FAQAccordion
                    data={faqData?.usersFAQsData}
                    isUsersQue={true}
                  />
                )}
                {activeTab === 2 && (
                  <FAQAccordion
                    data={faqData?.providerFAQsData}
                    isProvidersQue={true}
                  />
                )}
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default FAQSection;
