import { Button, Dialog } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { SuccessDialogImg } from "../../constant/ImagesS3";
import { styles } from "./styleSheet";
const SuccessDialog = ({
  open,
  onClose,
  title = "Successful!",
  message = "Your changes are saved successfully!",
  cancelText = "Okay",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: styles.confirmDialog,
      }}
    >
      <div style={styles.SuccessDialogImgBox}>
        <img
          src={SuccessDialogImg}
          alt="success"
          style={styles.SuccessDialogImg}
          loading="lazy"
        />
      </div>
      <div style={styles.confirmDialogTitle}>{title}</div>
      <div style={styles.confirmDialogMessage}>{message}</div>
      <div style={styles.confirmDialogBtnBox}>
        <Button onClick={onClose} style={styles.successDialogBtn}>
          {cancelText}
        </Button>
      </div>
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
};

export default SuccessDialog;
