import React from "react";
import "../../App.css";
import Experience from "../../components/Experience/Experience.jsx";
import FAQSection from "../../components/FAQSection/FAQSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import Roadmap from "../../components/Roadmap/Roadmap.jsx";
import SixDegreeSeperationSection from "../../components/SixDegreeSeperationSection/SixDegreeSeperationSection";
import WhyBataiyo from "../../components/WhyBataiyo/WhyBataiyo.jsx";
import Layout from "../../Layout.js";
function LandingPage() {
  return (
    <Layout>
      <HeroSection />
      <WhyBataiyo />
      <SixDegreeSeperationSection />
      <Experience />
      <Roadmap />
      <FAQSection />
    </Layout>
  );
}

export default LandingPage;
